import { Box } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";

import CustomButton from "components/CustomButton";
import { useWindowSize } from "components/useWindowSize";
import React, { useEffect } from "react";

const GridList = ({
  heading,
  button,
  list,
  background,
  paddingLeft,
  bottomText,
}) => {
  const { width } = useWindowSize();
  let [xxs, setXxs] = React.useState(false);

  useEffect(() => {
    setXxs(width < "382");
  }, [width]);

  return (
    <>
      <Box
        item
        xs={width < 500 ? 6 : 3}
        sm={4}
        md={3}
        sx={{
          paddingLeft: paddingLeft,
          justifyContent: "flex-start",
          paddingTop: { sm: 0, md: "50px" },
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <Typography
            align={"left"}
            color="text.secondary"
            sx={{
              fontFamily: { xs: "Arial", sm: "Gilroy-Bold", md: "Gilroy-Bold" },
              fontSize: { xs: "20px", sm: "30px", md: "30px" },
              lineHeight: "40px",
              marginBottom: "10px",
              fontWeight: { xs: 700, sm: 400, md: 400 },
              display: "flex",
              alignItems: "center",

              color: background,
            }}
          >
            {heading}
          </Typography>
          <CustomButton
            title={button}
            background={background}
            color={"white"}
            width={{
              xs: width < 320 ? "130px" : width < 350 ? "140px" : "161px",
              sm: "237px",
              md: "220px",
              lg: "237px",
            }}
            height={{ xs: "44px", sm: "50px", md: "50px" }}
            fontSize={{
              md: "15px",
              sm: "12px",
              xs: width < 350 ? "10px" : "12px",
            }}
            lineHeight={{ md: "18px", sm: "15px", xs: "15px" }}
            fontWeight={600}
          />
          <Box flexGrow={1} />
          <ListItem
            component="div"
            disableGutters
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              paddingTop: "25px",
              paddingLeft: {
                xs: "0px  !important",
                sm: "10px",
                md: "10px",
                lg: "10px",
              },
              width: { xs: width < 320 ? "147px !important" : "auto" },
            }}
          >
            {list.map((item, index) => {
              return (
                <ListItemButton
                  component="a"
                  target="_blank"
                  sx={{
                    margin: "0",
                    padding: "10px  0 10px 0",
                  }}
                  key={index}
                >
                  <ListItemIcon sx={{ minWidth: xxs ? "25px" : "40px" }}>
                    <Box
                      sx={{
                        background: background,
                        width: { xs: "16px", sm: "22px" },
                        height: { xs: "16px", sm: "22px" },
                        borderRadius: "25px",
                      }}
                    ></Box>
                  </ListItemIcon>
                  <ListItemText
                    component={"p"}
                    primary={item}
                    primaryTypographyProps={{
                      fontFamily: "Inter ",
                      fontStyle: " normal",
                      fontWeight: " 500",
                      fontSize: { xs: xxs ? "13px" : "16px", md: "20px" },
                      lineHeight: { xs: " 20px", sm: "40px" },
                      display: " flex",
                      alignItems: " center",

                      color: "#393841",
                    }}
                  />
                </ListItemButton>
              );
            })}
          </ListItem>
        </Box>
        {bottomText && (
          <Typography
            display={width > 450 ? "visible" : "none"}
            sx={{
              fontFamily: "Gilroy-Bold",
              fontSize: "24px",
              lineHeight: " 30px",
              letterSpacing: " 0em",
              textAlign: " left",
              color: background,
              height: "98px",
              maxWidth: width > 1000 ? "310px" : "220px",
            }}
          >
            {bottomText}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default GridList;
