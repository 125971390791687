/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import manThinking from "assets/manThinking.png";
import girlSmiling from "assets/girlSmiling.png";
import greenLine from "assets/greenLine.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomButton from "./CustomButton";
import Container from "components/Container";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";

const mock = [
  {
    image: manThinking,
    description:
      "Engaged Supporters are more likely to be motivated to act. Whether it be volunteering, donating or influencing others, every NPO should strive for 100% engagement with their supporters.  Direct Affect understands that engagement is not accomplished by outdated one-way messaging, but rather an ongoing process of communication and feedback that provides a unique experience for every one of their supporters.  ",
    title: "Non-Profit Organizations & Their Supporters",
    tag: "Bringing together",
  },
  {
    image: girlSmiling,
    description:
      "Direct Affect enables NPOs to reach supporters on their terms and with their interests in mind – affording the opportunity to more efficiently and effectively inform them, engage them and motivate them to actively participate. Whether it be financial support, volunteer support, or promoter support, Direct Affect is the non-profit community platform of the future.",
    title: "Supporter-centric<br/>Non-profit community engagement platform",
    tag: "The First",
  },
];

const TwoColumnBanner = () => {
  return (
    <Container
      sx={{
        maxWidth: "1680px !important",
        padding: "0px !important",
      }}
    >
      {" "}
      <Box container spacing={4} sx={{ mt: { lg: 5, md: 5, sm: 0, xs: 0 } }}>
        {mock.map((item, i) => (
          <Banner
            key={i}
            {...item}
            alignImageLeft={i % 2 === 0}
          />
        ))}
      </Box>
    </Container>
  );
};

export default TwoColumnBanner;

export const Banner = ({
  image,
  description,
  title,
  tag,
  alignImageLeft = true,
  hideSignUpButton = false,
}) => {
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });

  const navigate = useNavigate();
  return (
    <Box
      item
      xs={12}
      sx={{
        paddingLeft: {
          lg: alignImageLeft ? "0px !important" : "32px",
          md: "32px",
        },
        pt: {
          sm: "0 !important",
          xs: "0 !important",
          md: "32px !important",
          lg: "39px !important",
        },
      }}
    >
      <Box
        component={Card}
        width={1}
        height={1}
        boxShadow={0}
        display={"flex"}
        flexDirection={{
          xs: "column",
          md: alignImageLeft ? "row-reverse" : "row",
        }}
        sx={{
          backgroundImage: "none",
          bgcolor: "transparent",
          justifyContent: "flex-end",
          maxWidth: { sm: "100%" },
        }}
      >
        {isSm && !alignImageLeft && (
          <Divider
            sx={{ borderBottomWidth: 2, width: "100vw", mt: "37px" }}
          />
        )}
        <CardContent
          sx={{
            paddingX: { xs: 3, sm: 2, md: 4, lg: 0 },
            paddingY: { xs: 2, sm: 4, lg: 2 },
            width: { xs: 1, md: "50%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: { lg: "619px", md: "100%" },
            marginLeft: {
              lg: alignImageLeft ? "50px !important" : "auto !important",
            },
            marginRight: {
              lg: !alignImageLeft ? "50px !important" : "auto !important",
            },
          }}
        >
          <Box sx={{ maxWidth: { md: "619px" } }}>
            {tag ? 
              <Box
                sx={{
                  fontFamily: "Arial",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: {
                    lg: "28px",
                    md: "25px",
                    sm: "21px",
                    xs: "19px",
                  },
                  lineHeight: "60px",
                  display: "flex",
                  alignItems: "center",

                  color: "#1EBF79",
                }}
              >
                <img
                  src={greenLine}
                  loading="lazy"
                  decoding={"async"}
                  style={{
                    width: isSm ? "40px" : "100px",
                    background: "green",
                    height: "2px",
                  }}
                  alt={"Green line"}
                />{" "}
                &nbsp; &nbsp;
                {tag}
              </Box>
              : null
            }
          </Box>
          <Typography
            fontWeight={700}
            sx={{
              fontFamily: "CenturyGothic",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: {
                lg: "46px",
                md: "37px",
                sm: "30px",
                xs: "30px",
              },
              lineHeight: {
                lg: "50px",
                md: "45px",
                sm: "35px",
                xs: "35px",
              },
              letterSpacing: "0em",
              color: "#393841",
              maxWidth: "619px",
              width: { lg: "619px" },
              marginBottom: { md: "25px", sm: "10px", xs: "10px" },
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          ></Typography>
          <Typography
            color="text.secondary"
            sx={{
              fontFamily: "Arial",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: {
                lg: "18px",
                md: "15px",
                sm: "16px",
                xs: "16px",
              },
              lineHeight: {
                lg: "30px",
                md: "28px",
                sm: "25px",
                xs: "25px",
              },

              display: "flex",
              alignItems: "center",
              textAlign: "justify",
              maxWidth: "619px",
              color: "#2B2A35",
              width: "100%",
              letterSpacing: "0em",
            }}
          >
            {description}
          </Typography>
          {!hideSignUpButton ? <Box
            marginTop={2}
            display={"flex"}
            justifyContent={"flex-start"}
            sx={{ maxWidth: "619px" }}
          >
            <CustomButton
              title={"Learn More -"}
              width="160px"
              height="50px"
              background={"white"}
              color={"#1CAA9A"}
              border={"1px solid #1CAA9A"}
              fontFamily="Gilroy-Medium"
              fontSize={"18px"}
              lineHeight={"21px"}
              letterSpacing={"0em"}
              textAlign={"center"}
              margin={{ lg: "40px 0px 0px 0px", md: "40px 0px 0px 0px" }}
              onClick={() => navigate("/signup")}
            />
          </Box> : null}
        </CardContent>
        <Box
          sx={{
            width: { xs: 1, md: "50%" },
            "& .lazy-load-image-loaded": {
              height: 1,
              display: "flex !important",
            },
            display: "flex",
            justifyContent: {
              md: alignImageLeft ? "flex-start" : "flex-end",
              sm: alignImageLeft ? "flex-start" : "flex-end",
              xs: "center",
            },
          }}
        >
          <Box
            component={"img"}
            height={1}
            width={1}
            src={image}
            loading="lazy"
            decoding={"async"}
            alt="..."
            paddingX={{ xs: 0, sm: 2, md: 0 }}
            paddingY={{ xs: 0, sm: 0, md: 0 }}
            sx={{
              objectFit: { xs: "cover", md: "cover" },
              maxHeight: {
                lg: "588px",
                md: "588px",
                sm: "588px",
                xs: "255px",
              },
              maxWidth: {
                lg: "779px",
                md: "779px",
                sm: "779px",
                xs: "368px",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}
