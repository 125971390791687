import React from "react";
import Typography from "@mui/material/Typography";

const Heading = ({ children, mb, italic, head, underLine }) => {
  const headingStyle = {
    fontFamily: "Gilroy-Bold",
    fontStyle: italic ? "italic" : "normal",
    fontWeight: italic ? 400 : 400,
    fontSize: head ? { xs: "20px", md: "24px" } : { xs: "16px", md: "18px" },
    lineHeight: head ? { xs: "25px", md: "50px" } : { xs: "20px", md: "30px" },
    textTransform: "capitalize",
    color: "#393841",
    letterSpacing: "0em",
    textDecoration: underLine ? "underline" : "unset",
  };

  return (
    <Typography
      variant="h6"
      component="p"
      color="text.secondary"
      sx={headingStyle}
      mt={3}
      mb={mb || 2}
    >
      {children}
    </Typography>
  );
};

export default Heading;
