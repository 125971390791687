import React from 'react';
import Container from "../../components/Container";
import Layout from "../../layout";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import theme from "../../theme";
import Grid from "@mui/material/Grid";
import { inputGridStyle, inputStyle, supportValidationSchema } from "../../helper";
import Input from "../../components/Input";
import { useFormik } from "formik";
import Button from "@mui/material/Button";

export const buttonSX = {
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  background: theme.palette.primary.main,
  width: "10%",
  color: "white",
  textAlign: "center",
};


const Support = () => {

  const initialValues = {
    name: "",
    email: "",
    inquiry: "",
  };

  const onSubmit = async (values) => {
    const response = await fetch("https://us-central1-direct-affect-mvp.cloudfunctions.net/sendSupportEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://directaffect.net/",
      },
      body: JSON.stringify(values)
    }
  )

      if (response.ok) {
        alert("Message Sent");
      } else {
        alert("Message Failed");
      }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: supportValidationSchema,
    validateOnBlur: true,
    onSubmit,
  });

  return (
    <Container
      paddingX={0}
      paddingY={0}
      sx={{
        maxWidth: "100vw",
        height: { md: "100vh", sm: "100vh", xs: "100vh" },
      }}
    >
      <Layout>
        <Box
          paddingBottom={"50vh"}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            marginTop: "10vh",

          }}
        >
          <Typography
            sx={{
              fontFamily: "CenturyGothic",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: { lg: "45px", md: "48px", sm: "45px", xs: "43px" },
              lineHeight: { lg: " 35px", md: "32px", sm: "30px", xs: "35px" },
              textAlign: " center",
              color: "#393841",
            }}
          >
            {"Support"}
          </Typography>
          {
            <>
              <Typography
                sx={{
                  fontFamily: "Century-Gothic-Bold",
                  fontStyle: " normal",
                  fontWeight: " 500",
                  fontSize: { lg: "30px", md: "28px", sm: "25px", xs: "24px" },
                  lineHeight: { lg: " 30px", md: "28px", sm: "25px", xs: "24px" },

                  textAlign: " center",

                  color: "#393841",
                  marginTop: "48px",
                  marginBottom: "48px",
                }}
              >
                For Support, please complete the following form.
              </Typography>

            </>
          }

          <form onSubmit={formik.handleSubmit} style={{marginLeft:"4em", marginRight:"4em"}}>
            <Grid container spacing={4}>
                <Grid item xs={12} sx={inputGridStyle}>
                  <Input
                    title="Name"
                    name={"name"}
                    value={formik.values.name}
                    formik={formik}
                    titleStyle={{
                      ...inputStyle,
                      textAlign: "left",
                      width: "100%",
                      color: "#393841",
                      fontSize: { lg: "20px", md: "18px", sm: "16px", xs: "14px" },

                    }}
                    inputStyle={{...inputStyle}}
                    autoFocus={true}
                    placeHolder="Enter Your Full Name"
                  />
                </Grid>
              <Grid item xs={12} sx={inputGridStyle}>
                <Input
                  title="Email"
                  name={"email"}
                  value={formik.values.email}
                  type="email"
                  formik={formik}
                  titleStyle={{
                    ...inputStyle,
                    textAlign: "left",
                    width: "100%",
                    color: "#393841",
                    fontSize: { lg: "20px", md: "18px", sm: "16px", xs: "14px" },

                  }}
                  inputStyle={inputStyle}
                  placeHolder="Enter Email Address"
                />
              </Grid>

                <Grid item xs={12} sx={inputGridStyle}>
                  <Input
                    title="Your Support Inquiry"
                    name={"inquiry"}
                    type={'textarea'}
                    value={formik.values.inquiry}
                    formik={formik}
                    titleStyle={{
                      ...inputStyle,
                      textAlign: "left",
                      width: "100%",
                      color: "#393841",
                      fontSize: { lg: "20px", md: "18px", sm: "16px", xs: "14px" },
                    }}
                    inputStyle={inputStyle}
                    placeHolder="Describe your support question or issue here"
                  />
                </Grid>






              <Grid item container xs={12} sx={inputGridStyle}>

                <Button
                  type={"submit"}
                  target="blank"
                  size="large"
                  sx={buttonSX}
                >
                  {"Submit"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Layout>
    </Container>)
}

export default Support
