import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import logoFrame from "assets/logoFrame.svg";
import chevronRight from "assets/chevronRight.svg";
import { Helmet } from "react-helmet";

const Join = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const charityId = searchParams.get("id");
  const charityName = searchParams.get("charityName");


  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location.key]);

 const appArgumentString = `app-id=1595680630, app-argument=https://directaffectapp.page.link/join?id=${charityId}`

  return (
    <>
      <Helmet>
        <title>Join Direct Affect</title>
        <meta name="apple-itunes-app" content={appArgumentString}/>
      </Helmet>
      <div className={'join-container'}><img src={logoFrame} alt="logo"/>
        <p className={'join-typography'}>{charityName ? `You’ll need the Direct Affect app to join ${decodeURI(charityName)}.` : 'You’ll need the Direct Affect app to join'}</p>
        <span className={'join-app-store-container'}>
          <a className={'join-app-store-typography'} href={`https://apps.apple.com/us/app/id1595680630`}>Download</a>
          <img className={'join-chevron'} src={chevronRight} alt={'chevron'}/></span>
      </div>
        <span className={'open-app-container'}><p>Already Installed?</p><a className={"open-app-link"} href={`https://directaffectapp.page.link/join?id=${charityId}`}>Open in Direct Affect</a>.</span>
    </>
  );
};

export default Join;
