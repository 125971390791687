import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import logo from "assets/logo.svg";
import { useNavigate } from "react-router-dom";

const SidebarNav = () => {
  const theme = useTheme();
  const [activeLink, setActiveLink] = React.useState("");
  const navigate = useNavigate();

  React.useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : "");
  }, []);

  const navigation = [
    { path: "/organization-signup", title: "Organization Sign Up" },
    { path: "/signup", title: "User Registration" },
    { path: "/about-us", title: "About Us" },
  ];

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={"flex"}
          component="a"
          href="/"
          title="Logo"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={"img"}
            src={logo}
            loading="lazy"
            decoding={"async"}
            height={1}
            width={"191px"}
            sx={{ maxHeight: "44px" }}
            alt={"Logo"}
          />
        </Box>
      </Box>

      <Box paddingX={2} paddingY={2}>
        {navigation.map((item, index) => {
          return (
            <Button
              // component={"a"}
              variant={"h2"}
              // href={item.path}
              fullWidth
              sx={{
                justifyContent: "flex-start",
                color:
                  activeLink === item.path
                    ? theme.palette.primary.main
                    : theme.palette.text.primary,
                fontSize: "16px !important",
                fontWeight: activeLink === item.path ? 600 : 400,
                padding: "10px 0px 10px 0px",
                lineHeight: "19px",
                fontFamily: "Arial",
              }}
              key={index}
              onClick={() => navigate(item.path)}
            >
              {item.title}
            </Button>
          );
        })}
        <Box
          sx={{
            mb: 2,
            width: 1,
            mt: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            component="a"
            target="blank"
            size="large"
            sx={{
              background: theme.palette.primary.main,
              width: "100%",
              color: "white",
            }}
            href="https://admin.directaffect.net"
          >
            Login
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarNav;
