import React from "react";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import Container from "components/Container";
import Heading from "components/Typographies/Heading";
import Paragraph from "components/Typographies/Paragraph";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Main = () => {
  const navigate = useNavigate();
  const styling = {
    textDecoration: "underLine",
    fontSize: "inherit",
    lineHeight: "inherit",
    paddingLeft: "6px",
    paddingRight: "6px",
    cursor: "pointer",
  };

  const termsNavigate = () => {
    navigate("/term-of-service");
  };
  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: {
          lg: "98px 32px 108px 32px !important",
          md: "98px 32px 108px 32px !important",
          sm: "0px 32px 50px 32px !important",
          xs: "0px 32px 40px 32px !important",
        },
        maxWidth: "1364px !important",
      }}
    >
      <Box>
        <Heading children={"1. Introduction"} head />
        <Paragraph
          children={
            <>
              Direct Affect, Inc. a California Benefit Corporation
              (referred to here as “DA,” the “Company,” “we,” “us,” or “our”) is
              committed to protecting your privacy and your personally
              identifiable information. This Privacy Policy describes how and
              why we collect, use and share information about you that we obtain
              through your use of Direct Affect, (referred to as the “Service,”
              “Services,” our “Platform”, or the “DA App”).
              <br />
              <br />
              By using our Platform, you are accepting and consenting to the
              practices described in this Privacy Policy (as changed from time
              to time). You also agree to comply with the additional policies
              included in our Terms of Service which you can viewed{" "}
              <Typography
                component="span"
                color="#1AB29F"
                onClick={() => termsNavigate()}
                sx={{
                  textDecoration: "underLine",
                  cursor: "pointer",
                  fontSize: "inherit",
                  lineHeight: "inherit",
                }}
              >
                here
              </Typography>{" "}
              and which may be updated or revised from time to time.
              <br />
              <br />
              If, for any reason, you do not agree to the terms of this Privacy
              Policy and our use of your information, please delete your account
              and/or send us an email to
              <Typography
                  component="a"
                  href="mailto:support@directaffect.net"
                  color="#1AB29F"
                  sx={styling}
                >
                support@directaffect.net
              </Typography>
               and stop using our Platform.
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
          }}
        />
        <Heading children={"2. Information We Collect"} head />
        <Paragraph
          children={
            <>
              We collect certain information to help us operate and provide our
              Services. Below is a detailed description of the personal
              information we may collect, where we obtain it, why we collect it,
              and who we share it with.
              <br />
              <br />
              It is important to note that, other than basic information (e.g.
              your name, email address and mobile phone number) that we may
              obtain from a Non-Profit Organization (“NPO”) to facilitate
              inviting you to join Direct Affect and/or their Direct Affect
              community, all information we collect is obtained solely from you
              and your activity within our own Platform and we do not track any
              user activity outside of our Platform. We are in no way involved
              in cross application data sharing nor using any data provided or
              offered by third parties to track or monitor user activity in
              other products or services. Except as otherwise provided herein,
              in no event will we share information that personally identifies
              you with advertisers or for any other purpose.
            </>
          }
        />

        <Heading
          children={"2.1 Your name, email address and/or mobile phone number"}
          mb={"0px !important"}
          underLine
        />
        <Paragraph
          children={
            <Typography style={{ marginTop: "20px" }}>
              <Typography>
                <b>We collect it:</b> 1) to establish your account with us and
                enable your affiliation as a member of particular NPO’s, 2) to
                confirm your identity for the NPO’s of which you are or want to
                become a member, 3) to enable a Donor Advised Fund Account (“DAF”) to be established on
                your behalf, 4) to complete reporting and other services
                available on the Platform and to communicate with you, 5) to
                provide you with information about products and services that
                might interest you, and 6) to facilitate internal business
                analysis and other business purposes.
              </Typography>
              <br />
              <Typography>
                <b>We share it with </b>NPO’s of which you become a member
                and/or to which you donate and organizations that we partner
                with to provide user support, marketing, and financial services
                including DAF Services for your account.
              </Typography>
            </Typography>
          }
        />
        <Heading
          underLine
          children={
            "2.2 Information regarding your interests and the categories of causes you care about"
          }
          mb={"0px !important"}
        />
        <Paragraph
          children={
            <Typography style={{ marginTop: "20px" }}>
              <Typography>
                <b>We collect it</b> to provide you with curated news and to
                help present relevant NPO’s to you as you explore new ways to
                support causes you care about.
              </Typography>
              <br />
              <Typography>
                <b>We share it with </b>organizations that we partner with to
                provide user support and advertising.
              </Typography>
            </Typography>
          }
        />
        <Heading
          underLine
          children={"2.3 Your demographic information"}
          mb={"0px !important"}
        />
        <Paragraph
          children={
            <Typography style={{ marginTop: "20px" }}>
              <Typography>
                <b>We collect it</b> to help improve our services and to enable
                advertising and for other business purposes.
              </Typography>
              <br />
              <Typography>
                <b>We share it with </b>organizations that we partner with to
                provide user support and advertising and with NPO’s for
                analytics, marketing, and other anonymized reporting to help
                them optimize their engagement efforts.
              </Typography>
            </Typography>
          }
        />
        <Heading
          underLine
          children={
            "2.4 Financial information required to transfer funds at your request along with Transaction Date, Time, Amount & Recipient "
          }
          mb={"0px !important"}
        />
        <Paragraph
          children={
            <Typography style={{ marginTop: "20px" }}>
              <Typography>
                <b>We collect it</b> to enable the funding of your DAF account
                and to facilitate your grant request(s) from your DAF to NPOs, 2) to facilitate
                necessary IRS reporting (i.e. Donor Advised Funds and receipts
                for Donations in Kind), and 3) to provide other reports and
                services to you.
              </Typography>
              <br />
              <Typography>
                <b>We share it with </b>our technology and business partners and
                authorized third-party vendors who help us provide financial services including DAF services for your account and other specialized
                services including customer support, business analytics and
                advertising and with NPO’s for analytics, marketing, and other
                anonymized reporting to help them optimize their engagement
                efforts.
              </Typography>
            </Typography>
          }
        />

        <Heading
          underLine
          children={
            "2.5 Information you provide about your interest in having a specific NPO joining the Platform"
          }
          mb={{ xs: "4px !important", md: "0px !important" }}
        />
        <Paragraph
          children={
            <Typography style={{ marginTop: "20px" }}>
              <Typography>
                <b>We collect it</b> to communicate with the NPO and encourage
                them to join the Platform.
              </Typography>
              <br />
              <Typography>
                <b>We share it with </b>authorized third-party vendors who help
                us provide specialized services such as customer support,
                business analytics and advertising.
              </Typography>
            </Typography>
          }
        />

        <Heading
          underLine
          children={
            "2.6 Legal information, such as contact information, fraud checks or concerns raised about your transactions, the payment card you use (EFT or otherwise), payment card refusals, ACH problems, suspected crimes, complaints and other claims"
          }
          mb={{ xs: "4px !important", md: "0px !important" }}
        />
        <Paragraph
          children={
            <Typography style={{ marginTop: "20px" }}>
              <Typography>
                <b>We get it from You and/or: 1) </b> the police, crime and
                fraud prevention agencies and regulators, 2) NPO’s and Payment
                processing providers, and 3) your/our professional advisors and
                representatives.
              </Typography>
              <br />
              <Typography>
                <b>We collect it</b> to protect you and other users and
                businesses against criminal activities and risks and to make
                sure we understand and can meet our legal obligations to you and
                others and can defend ourselves.
              </Typography>
              <br />
              <Typography>
                <b>We share it with </b>service providers who help us with fraud
                protection and credit risk reduction, and with law enforcement
                and other governmental authorities in accordance with applicable
                law.
              </Typography>
            </Typography>
          }
        />

        <Heading
          underLine
          children={
            "2.7 Communications with you, such as emails you send us, messages you respond to, feedback forms you complete, phone calls, chats any other communications with you."
          }
          mb={"0px !important"}
        />
        <Paragraph
          children={
            <Typography style={{ marginTop: "20px" }}>
              <Typography>
                <b>We collect it</b> to handle your requests, 2) to contact you
                when necessary or requested, including responding to your
                questions and comments and providing customer support, and 3) to
                ensure the Platform and our Services are meeting your
                expectations and performing as intended.
              </Typography>
              <br />
              <Typography>
                <b>We share it with </b>Our technology and business partners and
                authorized third-party vendors who help us provide specialized
                services including customer support, business analytics and
                advertising.
              </Typography>
            </Typography>
          }
        />

        <Heading
          underLine
          children={
            "2.8 Information automatically collected from the App or your browser, including browser type, viewed pages, links that are clicked, IP address, mobile device identifier or other unique identifier, the amount of time you spend viewing or using the app, the number of times you return, other usage data, emails we send that you open, click throughs to download the App, updated we send that you open and/or reply to, news articles that you open, calendar items that you open and/or add to your personal calendar, your usage of the App immediately prior to and immediately after making a donation"
          }
          mb={"0px !important"}
        />
        <Paragraph
          children={
            <Typography style={{ marginTop: "20px" }}>
              <Typography>
                <b>We collect it</b> to improve our App and/or website, improve
                our services, customer service and/or the user experience and to
                anonymize and combine with other information we collect in order
                to develop value added products and services for NPO’s to help
                them improve their engagement effectiveness and efficiency
              </Typography>
              <br />
              <Typography>
                <b>We share it with </b>our technology and business partners and
                authorized third-party vendors who help us provide specialized
                services including customer support, business analytics and
                advertising and with NPO’s that subscribe to value added
                products and services from DA.
              </Typography>
            </Typography>
          }
        />
        <Heading children={"3. Disclosure to Third Parties"} head />
        <Paragraph
          children={
            <>
              <Typography>
                We do not display your contact information publicly or disclose
                personally identifiable information to third parties, except for
                when
              </Typography>

              <Typography>
                (i) we have your permission to make the disclosure,
              </Typography>
              <Typography>
                (ii) the disclosure is necessary for the purpose for which the
                information was obtained,{" "}
              </Typography>
              <Typography>
                (iii) a third-party is assisting us to provide or manage our
                Platform, or{" "}
              </Typography>
              <Typography>
                (iv) where otherwise stated in this policy. We also may disclose
                information when, in our sole discretion, the disclosure is
                necessary for the establishment or maintenance of legal claims
                or legal compliance, to satisfy any law, regulation, subpoena or
                government request, or in connection with litigation.
              </Typography>
              <br />
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
          }}
        />
        <Heading children={"4. Business Transfers"} head />
        <Paragraph
          children={
            <>
              In the event we are acquired by or merged or consolidated into
              another entity, or if there is a sale of our assets, your
              information may be transferred to the entity acquiring us or our
              assets or the entity that survives the merger or consolidation.
              You acknowledge and agree that in the foregoing circumstances,
              your information may be disclosed to such third party provided
              such party adheres to the terms of this policy or a similar policy
              which protects your information to the same extent as this policy.{" "}
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
          }}
        />
        <Heading children={"5. Email – Opt Out"} head />
        <Paragraph
          children={
            <>
              You may have the opportunity to receive certain communications
              from us related to our Services or other communications from NPOs.
              If you provide us with your email address in order to receive
              communications, you can opt out at any time by using the
              unsubscribe links at the bottom of our emails.
              <br />
              Please note that certain emails may be necessary to provide you
              with our Services. You will continue to receive these emails, if
              appropriate, even if you unsubscribe from our optional
              communications.
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
          }}
        />
        <Heading children={"6. Security"} head />
        <Paragraph
          children={
            <>
              We use commercially reasonable efforts to secure your data through
              the programming of our Services and the use of security measures
              that we deem appropriate for the type of data provided. However,
              we cannot completely guarantee that no part of our system or site
              will ever fail or be compromised. If you ever suspect that our
              site or services has contributed to your personal information
              being compromised, please contact us at
              <Typography
                component="a"
                href="mailto:support@directaffect.net"
                color="#1AB29F"
                sx={styling}
              >
                support@directaffect.net
              </Typography>{" "}
              immediately. If we determine a security breach has occurred, we
              will promptly investigate the issue and notify you so that we can
              resolve the matter.
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
          }}
        />
        <Heading children={"7. Persons under the Age of 18"} head />
        <Paragraph
          children={
            <>
              Our Platform is not directed to individuals under the age of 18,
              and we request that individuals under 18 not utilize our platform.
              We do not knowingly collect personal information from persons
              under the age of 18. If we learn that a child under the age of 18
              has submitted personally identifiable information online, we will
              take all reasonable measures to delete such information from our
              databases and to not use such information for any purpose (except
              where necessary to protect the safety of the minor or others as
              required or allowed by law). If you become aware of any personally
              identifiable information we have collected from individuals under
              18, please contact us at
              <Typography
                component="a"
                href="mailto:support@directaffect.net"
                color="#1AB29F"
                sx={styling}
              >
                support@directaffect.net
              </Typography>
              .
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
          }}
        />
        <Heading
          children={
            "8. For California Users Only: Your California Privacy Rights"
          }
          head
        />
        <Paragraph
          children={
            <>
              California residents who provide personal information in obtaining
              products or services for personal, family, or household use are
              entitled to request and obtain from us once a calendar year
              information about the customer information we shared with third
              parties for their own direct marketing purposes, including the
              categories of information and the names and addresses of those
              businesses with which we have shared such information. As
              discussed elsewhere in this Privacy Policy, we do not currently
              share the personal information of California residents with third
              parties for their own direct marketing purposes. However, if you
              have further questions about our privacy practices and compliance
              with California law, please contact us as explained below.
              <br />
              <br />
              If you are a California resident, the California Consumer Privacy
              Act provides you with the following rights with respect to your
              personal information:
              <br />
            </>
          }
        />

        <Paragraph
          children={
            <ul style={{ marginTop: "0px", marginBottom: 0 }}>
              <li>
                The right to request to know the categories or specific pieces
                of personal information we have collected, used, disclosed and
                sold about you. To submit a request to know, please email us at
                <Typography
                  component="a"
                  href="mailto:support@directaffect.net"
                  color="#1AB29F"
                  sx={styling}
                >
                  support@directaffect.net
                </Typography>
              </li>
              <li>
                The right to request that we delete any personal information we
                have collected about you. To submit a request for deletion,
                please email us at
                <Typography
                  component="a"
                  href="mailto:support@directaffect.net"
                  color="#1AB29F"
                  sx={styling}
                >
                  support@directaffect.net
                </Typography>
              </li>
            </ul>
          }
        />
        <Paragraph>
          When you exercise these rights and submit a request to us, we will
          verify your identity directly or via third-party provider(s).
        </Paragraph>
        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
          }}
        />
        <Heading children={"9. Changes to Our Privacy Policy"} head />
        <Paragraph
          children={
            <>
              The Company will occasionally update this Privacy Policy. We
              encourage you to periodically review this Statement to be informed
              of how DA is protecting your information.{" "}
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
          }}
        />
        <Heading children={"10. Contact Us"} head />
        <Paragraph
          children={
            <>
              If any of the items above are unclear or you have further
              questions, or if you would like to request a copy of this Privacy
              Policy in a different format, please contact us at
              <Typography
                component="a"
                href="mailto:support@directaffect.net"
                color="#1AB29F"
                sx={styling}
              >
                support@directaffect.net
              </Typography>
            </>
          }
        />
      </Box>
    </Container>
  );
};

export default Main;
