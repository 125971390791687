import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "components/Container";
import { useWindowSize } from "components/useWindowSize";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const { width } = useWindowSize();
  const navigate = useNavigate();

  const bottomLinksStyle = {
    fontFamily: "Arial",
    fontWeight: 400,
    letterSpacing: "0em",
    textAlign: "right",
    fontStyle: " normal",
    fontSize: { xs: "10px", sm: "10px", md: "18px" },
    lineHeight: " 30px",
    color: "#2B2A35",
    cursor: "pointer",
  };
  return (
    <Container
      paddingY={0}
      sx={{
        height: { xs: "46px", sm: "46px", md: "80px" },
        display: "flex",
        justifyContent: "center",
      }}
    >
      {" "}
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" },
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "10px",
              sm: "12px",
              md: "18px",
            },
            fontWeight: 400,
            lineHeight: "30px",
            textAlign: "center",
            color: "#2B2A35",
            fontFamily: "Arial",
            letterSpacing: "0em",
          }}
        >
          © 2024 Direct Affect, Inc. All Rights Reserved.
        </Typography>
        <Box
          sx={{
            display: " flex",
            alignItems: " center",
            width: { lg: " 450px", md: "400px", sm: "250px", xs: "100%" },
            justifyContent: "space-between",
          }}
        >
          <Typography
            component={"p"}
            sx={{
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: { xs: "10px", sm: "12px", md: "18px" },
              lineHeight: "30px",
              textAlign: "right",
              color: "#2B2A35",
              cursor: "pointer",
              fontFamily: "Arial",
              fontWeight: 400,
              letterSpacing: "0em",
            }}
            onClick={() =>
              navigate("/term-of-service", { state: { newId: Math.random() } })
            }
          >
            Terms
          </Typography>
          <Typography
            component={"p"}
            sx={{
              fontFamily: "Arial",
              letterSpacing: "0em",
              fontStyle: " normal",
              fontWeight: " 400",
              fontSize: { xs: "10px", sm: "10px", md: "18px" },
              lineHeight: " 30px",
              textAlign: " right",
              color: "#2B2A35",
              cursor: "pointer",
            }}
            onClick={() =>
              navigate("/privacy-policy", { state: { newId: Math.random() } })
            }
          >
            Privacy Policy
          </Typography>
          <Typography
            component={"p"}
            sx={{
              fontFamily: "Arial",
              fontWeight: 400,
              letterSpacing: "0em",
              fontStyle: " normal",
              fontWeight: " 400",
              fontSize: { xs: "10px", sm: "10px", md: "18px" },
              lineHeight: " 30px",
              textAlign: " right",
              color: "#2B2A35",
              cursor: "pointer",
            }}
            onClick={() =>
              navigate("/policies", { state: { newId: Math.random() } })
            }
          >
            Policies
          </Typography>
          <Typography
            component={"p"}
            sx={{
              fontFamily: "Arial",
              letterSpacing: "0em",
              fontStyle: " normal",
              fontWeight: " 400",
              fontSize: { xs: "10px", sm: "10px", md: "18px" },
              lineHeight: " 30px",
              textAlign: " right",
              color: "#2B2A35",
              cursor: "pointer",
              a: {
                textDecoration: "none",
                color: "#2B2A35",
              },
            }}
          >
            <a href={'/support'} target = "_blank" rel="noreferrer">Support</a>
          </Typography>
        </Box>
      </Grid>
    </Container>
  );
};

export default Footer;
