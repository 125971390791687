import React from "react";
import Layout from "layout/index.js";
import Main from "./components/Main/Main";
import Hero from "components/Hero/Hero";
import policies from "assets/policies.png";
import { useLocation } from "react-router-dom";

const Policies = () => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location.key]);
  return (
    <Layout hr>
      <Hero
        path={"Home / Policies"}
        title={"Policies"}
        lastUpdated={"Last Updated: June 5, 2024"}
        image={policies}
      />
      <Main />
    </Layout>
  );
};

export default Policies;
