import React from "react";
import Box from "@mui/material/Box";
import EmpowerBG from "assets/empowerBG.png";
import reporting from "assets/reporting.png";
import donation from "assets/donation.png";
import updates from "assets/updates.png";
import membership from "assets/membership.png";
import news from "assets/news.png";
import feedback from "assets/feedback.png";
import logo from "assets/logo-white.svg";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Container from "components/Container";
import { Typography } from "@mui/material";
import { useWindowSize } from "components/useWindowSize";

const EmpoweringSM = () => {
  const theme = useTheme();

  const { width } = useWindowSize();
  const isXXS = width < 430;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: { lg: 13, md: 13, sm: 8, xs: 8 },
        background: `url(${EmpowerBG}) no-repeat center`,
        backgroundSize: "cover",
        backdropFilter: "brightness(0)",
        height: { xs: "540px", sm: "479px", md: "650px", lg: "679px" },
      }}
    >
      <Box
        sx={{
          width: "500px",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box marginBottom={5}>
          <Box
            component={"img"}
            src={logo}
            loading="lazy"
            decoding={"async"}
            maxWidth={"281px"}
          />
          <Typography
            sx={{
              fontFamily: "CenturyGothic",
              fontSize: "22px",
              fontWeight: 700,
              lineHeight: "30px",
              letterSpacing: "0em",
              textAlign: "center",
              color: "white",
              maxWidth: "314px",
            }}
          >
            Empowering and engaging supporters is the key to NPOs accomplishing
            their mission
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              maxWidth: "200px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              component={"img"}
              src={donation}
              marginBottom={2}
              loading="lazy"
              decoding={"async"}
              sx={{
                maxWidth: {
                  xs: isXXS ? "150px" : "200px",
                  sm: "200px",
                  md: "220px",
                  lg: "281px",
                },
              }}
            />
            <Box
              component={"img"}
              src={reporting}
              marginBottom={2}

              loading="lazy"
              decoding={"async"}
              sx={{
                maxWidth: {
                  xs: isXXS ? "150px" : "200px",
                  sm: "200px",
                  md: "220px",
                  lg: "281px",
                },
              }}
            />
            <Box
              component={"img"}
              src={updates}
              loading="lazy"
              decoding={"async"}
              sx={{
                maxWidth: {
                  xs: isXXS ? "150px" : "200px",
                  sm: "200px",
                  md: "220px",
                  lg: "281px",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              maxWidth: "200px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              component={"img"}
              src={membership}
              loading="lazy"
              decoding={"async"}
              sx={{
                maxWidth: {
                  xs: isXXS ? "150px" : "200px",
                  sm: "200px",
                  md: "220px",
                  lg: "281px",
                },
              }}
            />
            <Box
              component={"img"}
              src={news}
              loading="lazy"
              decoding={"async"}
              sx={{
                maxWidth: {
                  xs: isXXS ? "150px" : "200px",
                  sm: "200px",
                  md: "220px",
                  lg: "281px",
                },
              }}
            />
            <Box
              component={"img"}
              src={feedback}
              loading="lazy"
              decoding={"async"}
              sx={{
                maxWidth: {
                  xs: isXXS ? "150px" : "200px",
                  sm: "200px",
                  md: "220px",
                  lg: "281px",
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EmpoweringSM;
