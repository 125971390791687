import React from "react";
import { Routes as ReactRoutes, Route, Navigate } from "react-router-dom";
import Aboutus from "views/Aboutus/Aboutus";
import Privacypolicy from "views/Privacypolicy/Privacypolicy";
import Signin from "views/Signin/Index";
import Signup from "views/Signup/Signup";
import TOS from "views/Tos/TOS";
import Home from "./views/Home/Home";
import Join from "./views/Join/Join";
import Policies from "./views/Policies/Policies"
import Support from "./views/Support/Support";
import OrganizationSignup from "./views/OrganizationSignUp/OrganizationSignUp";

const Routes = () => {
  const routes = [
    {
      path: "/",
      renderer: (params = {}) => <Home {...params} />,
    },
    {
      path: "/signin",
      renderer: (params = {}) => <Signin {...params} />,
    },
    {
      path: "/signup",
      renderer: (params = {}) => <Signup {...params} />,
    },
    {
      path: "/about-us",
      renderer: (params = {}) => <Aboutus {...params} />,
    },
    {
      path: "/term-of-service",
      renderer: (params = {}) => <TOS {...params} />,
    },
    {
      path: "/privacy-policy",
      renderer: (params = {}) => <Privacypolicy {...params} />,
    },{
      path: "/join",
      renderer: (params = {}) => <Join {...params} />,
    },
    {
      path: "/staging/join",
      renderer: (params = {}) => <Join {...params} />,
    },
    {
      path: "/policies",
      renderer: (params = {}) => <Policies {...params} />,
    },
    {
      path: "/support",
      renderer: (params = {}) => <Support {...params} />,
    },
    {
      path: "/organization-signup",
      renderer: (params = {}) => <OrganizationSignup {...params} />,
    },
  ];
  return (
    <ReactRoutes>
      {routes.map((item, i) => (
        <Route key={i} path={item.path} element={item.renderer()} />
      ))}

      <Route path="*" element={<Navigate replace to="/" />} />
    </ReactRoutes>
  );
};

export default Routes;
