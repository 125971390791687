/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import heroMain from "assets/heroMain.png";
import heroBG from "assets/heroBG.png";
import heroBGMob from "assets/heroBGMob.png";
import Container from "components/Container";
import CustomButton from "components/CustomButton";
import lineImg from "assets/lineImg.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useWindowSize } from "components/useWindowSize";
import { useNavigate } from "react-router-dom";
import logo from "assets/logo-white.svg";

const Hero = () => {
  const theme = useTheme();
  const { width } = useWindowSize();
  const navigate = useNavigate();

  const isSm = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });
  const isSmall = useMediaQuery(theme.breakpoints.down("md"), {
    defaultMatches: true,
  });

  const GridItemHeadlineBlock = () => (
    <Box
      // marginBottom={4}
      data-aos="fade-up"
      textAlign={"center"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box
        display={"flex"}
        component="a"
        href="/"
        title="logo"
        // width={{ xs: 100, md: 120 }}
      >
        <Box
          component={"img"}
          src={logo}
          loading="lazy"
          decoding={"async"}
          sx={{
            maxHeight: "136px",
            width: { md: "596px", sm: "207px", xs: "207px" },
            height: { md: 1, sm: "48px", xs: "48px" },
          }}
          alt={"logo"}
        />
      </Box>
      <Typography
        gutterBottom
        sx={{
          fontFamily: "CenturyGothic",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: { xs: "30px", sm: "30px", md: "50px", lg: "50px" },
          lineHeight: { xs: "35px", sm: "35px", md: "50px", lg: "60px" },
          letterSpacing: "0em",
          maxWidth: "810px",

          color: "#FFFFFF",
        }}
      >
        The Non-profit Community Engagement Platform
      </Typography>
      <Box sx={{ pt: 2 }}>
        <CustomButton
          title={"Learn More -"}
          color={"white"}
          width="160px"
          height="50px"
          fontSize={"18px"}
          // backgroundColor={""}
          onClick={() => navigate("/signup")}
        />
      </Box>
    </Box>
  );

  return (
    <Box
      height={isSm ? "414px" : "770px"}
      position={"relative"}
      sx={{
        // paddingTop: { lg: 13, md: 13, sm: 8, xs: 8 },
        background: `url(${heroBG}) no-repeat center`,
        backgroundSize: "cover",
        backdropFilter: "brightness(0)",
        height: { lg: "770px", md: "700px", sm: "auto" },
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
      }}
    >
      <Container
        position={"relative"}
        zIndex={2}
        sx={{
          // paddingTop: "0px !important",
          // padding: { xs: "0 25px 16px 20px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            sx={{
              marginTop: { sm: "45px", md: 0 },
              width: { md: 0.7, sm: "auto", lg: 1 },
            }}
          >
            <GridItemHeadlineBlock />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
