import React from "react";
import SigninSignup from "assets/SigninSignup.png";

import LoginLogo from "assets/logo.svg";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import CustomButton from "components/CustomButton";
import { useNavigate } from "react-router-dom";
const SideScreen = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        flex: { xs: "0 0 100%", md: "0 0 50%" },
        flexDirection: "column",
        position: "relative",
        maxWidth: { xs: "100%", md: "700px", lg: "47vw" },
        order: { xs: 1, md: 2, lg: 2 },
        minHeight: { xs: "auto", md: "100vh" },
        backdropFilter: "brightness(0)",
        alignItems: "center",
        justifyContent: "center",
        // height: "100vh",
        display: { xs: "none", sm: "none", md: "flex" },
      }}
    >
      <Box
        sx={{
          position: { xs: "relative", md: "absolute" },
          zIndex: 2,
          marginTop: "-65px !important",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            component={"img"}
            effect="blur"
            src={LoginLogo}
            loading="lazy"
            decoding={"async"}
            width={1}
            sx={{
              maxWidth: "353px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          />
          <Typography
            sx={{
              fontFamily: "Century-Gothic-Bold",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: { lg: "22px", md: "19px", sm: "20px" },
              lineHeight: "30px",

              textAlign: "center",

              color: "#FFFFFF",
            }}
          >
            Non-profit Community Engagement Platform
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
      </Box>

      {/* <Box
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "start",
          display: "flex",
        }}
      > */}
      {/* <CustomButton title={"Go to Home Page "} /> */}
      {/* </Box> */}
      <Box
        sx={{
          overflow: "hidden",
          left: "0%",
          width: 1,
          height: 1,
          position: { xs: "relative", md: "absolute" },
          display: { xs: "none", sm: "none", md: "flex" },
          borderWidth: "17px 0px 17px 19px",
          borderColor: "white",
          borderStyle: "solid",
        }}
      >
        <Box
          component={"img"}
          effect="blur"
          src={SigninSignup}
          loading="lazy"
          decoding={"async"}
          height={{ xs: "auto", md: 1 }}
          maxHeight={{ xs: 300, md: 1 }}
          width={1}
          maxWidth={1}
          sx={{
            filter: theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
            zIndex: 1,
          }}
        />
      </Box>
    </Box>
  );
};

export default SideScreen;
