import { createTheme } from "@mui/material/styles";
import { general } from "./palette";

let theme = createTheme({
  palette: general,
});

theme = createTheme(theme, {
  palette: {
    info: {
      main: theme.palette.secondary.main,
    },
  },
});

export default theme;
