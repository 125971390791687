import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import supporterimage from "assets/supporterimage.png";
import { Container } from "@mui/material";
import GridList from "./GridList";
import { useWindowSize } from "components/useWindowSize";

const Supporters = () => {
  const supporterList = [
    "Feedback to NPO",
    "Volunteer",
    "Contributions",
    "Influence others",
  ];
  const nonProfilList = ["Communicate ", "Energize ", "Motivate ", "Appeal"];
  const { width } = useWindowSize();
  let [xxs, setXxs] = React.useState(false);

  useEffect(() => {
    setXxs(width < "382");
  }, [width]);
  return (
    <Container sx={{ padding: "0px !important" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            // maxWidth: "762px",
            marginBottom: { xs: "30px", sm: "45px", md: "70px" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            align={"center"}
            gutterBottom
            sx={{
              fontFamily: "CenturyGothic",
              fontSize: { xs: "20px", sm: "30px", md: " 46px" },
              lineHeight: { xs: "30px", sm: "35px", md: " 54px" },
              textAlign: " center",
              fontWeight: 400,
              letterSpacing: "0em",

              color: "#393841",
            }}
          >
            Fast, frictionless, personalized engagement between Non-profit
            organizations and their supporters
          </Typography>
        </Box>

        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: {
              md: "space-between",
              sm: "space-evenly",
              xs: "space-evenly",
              flexDirection: { xs: "column" },
            },
          }}
        >
          <GridList
            heading={"Non-profit"}
            button={"Personalized & Targeted"}
            list={nonProfilList}
            background={"#04AA46"}
            paddingLeft={{ xs: "0", sm: "16px", md: "16px", lg: "16px" }}
          />

          <Box
            xs={12}
            md={4}
            sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
          >
            <Box
              component={"img"}
              height={1}
              width={1}
              src={supporterimage}
              loading="lazy"
              decoding={"async"}
              alt="..."
              sx={{
                objectFit: "cover",
                height: "606px",
                width: "379px",
              }}
            />
          </Box>

          <GridList
            heading={"Supporter"}
            button={"Active & Engaged"}
            list={supporterList}
            background={"#117249"}
            bottomText={"Supporters are connected because they care."}
          />
        </Grid>
        <Typography
          display={width < 450 ? "visible" : "none"}
          sx={{
            fontFamily: "Gilroy-Bold",
            fontSize: { lg: "24px", md: "24px", sm: "22px", xs: "20px" },
            lineHeight: " 30px",
            letterSpacing: " 0em",
            textAlign: " left",
            color: "#117249",
            height: "98px",
          }}
        >
          Supporters are connected because they care.
        </Typography>
      </Box>
    </Container>
  );
};

export default Supporters;
