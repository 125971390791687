import * as yup from "yup";

export const pages = {
  Wrenners: [
    {
      title: "Organization Sign Up",
      href: "/signup",
    },
  ],
  trade: [
    {
      title: "User Registration",
      href: "/signup",
    },
  ],
  about: [
    {
      title: "About Us",
      href: "/about-us",
    },
  ],
};

export const validationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  password: yup
    .string()
    .required("Please specify your password")
    .min(8, "The password should have at minimum length of 8"),
});

export const organizationSignUpValidationSchema = yup.object({
  name: yup.string()
    .trim().required("Name is required."),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  npoName: yup.string().trim().max(250).required("Non-profit organization name is required."),})

export const memberSignUpValidationSchema = yup.object({
  name: yup.string()
    .trim().required("Name is required."),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),})


export const supportValidationSchema = yup.object({
  name: yup.string()
    .trim().required("Name is required."),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  inquiry: yup.string().trim().max(250).required("Inquiry is required."),})



export const inputStyle = {
  fontFamily: "Arial",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "22px",
  display: "flex",
  maxWidth: "500px",
  textAlign: "left",
  width: "100%",
  color: "#393841",
  borderRadius: "5px",
};

export const inputGridStyle = {
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  borderRadius: "5px",
  border:"none"
};
