import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import Container from "components/Container";

import { Topbar, Sidebar, Footer } from "./components";
import { pages } from "helper";

const Layout = ({ children,hr }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });

  return (
    <Box>
      <AppBar
        position={"sticky"}
        sx={{
          top: 0,
          backgroundColor: "white",
          height: { sm: "80px", md: "100px" },
          justifyContent: "center",
        }}
        elevation={trigger ? 0 : 0}
      >
        <Container paddingY={1} sx={{ padding: { xs: "8px 16px 8px 25px" } }}>
          <Topbar onSidebarOpen={handleSidebarOpen} pages={pages} />
        </Container>
      </AppBar>
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
      />
      <main >
        {children}
        {hr && <hr style={{ padding: "0px", margin: "0px" }} />}
      </main>

      <Footer />
    </Box>
  );
};

export default Layout;
