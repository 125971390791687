import React from "react";
import Layout from "layout/index.js";
import Hero from "./components/Hero/Hero";
import TwoColumnBanner from "components/TwoColumnBanner";
// import { Container } from "@mui/material";
import GetStarted from "./components/GetStarted";
import Container from "components/Container";
import Supporters from "./components/Supporters";
import Carousel from "./components/Carousel";
import Empowering from "./components/Empowering";
import Support from "./components/Support";
import EmpoweringSM from "./components/Empowering/EmpoweringSM";
import { useWindowSize } from "components/useWindowSize";

// const isXs = useMediaQuery(theme.breakpoints.down("sm"), {
//   defaultMatches: true,
// });

const Home = () => {
  const { width } = useWindowSize();
  const isXs = width < 800;
  return (
    <Layout hr>
      <Hero />
      <TwoColumnBanner />
      {isXs ? <EmpoweringSM /> : <Empowering />}
      {/* <Carousel /> */}
      <Support />
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#F5F5F5",
          width: "100%",
          maxWidth: "auto !important",
          marginTop: { xs: "50px", sm: "70px" },
          paddingBottom: "0px !important",
          // paddingTop: "65px !important",
        }}
      >
        <Supporters />
      </Container>
      <GetStarted />
    </Layout>
  );
};

export default Home;
