import React from "react";
import Layout from "layout/index.js";
import Main from "./components/Main/Main";
import GetStarted from "views/Home/components/GetStarted";
import Container from "components/Container";
import Hero from "components/Hero/Hero";
import AboutusImage from "assets/Aboutus.png";

const Aboutus = () => {
  return (
    <Layout>
      <Hero title={"About Us"} image={AboutusImage} />
      <Main />
      <Container
        sx={{
          maxWidth: "100vw",
          background: "#F5F5F5",
          padding: "56px 0 50px 0 !important",
          borderBottom: "1px solid #d5d0d0",
        }}
      >
        <GetStarted padding={"0px !important"} />
      </Container>
    </Layout>
  );
};

export default Aboutus;
