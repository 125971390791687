import React from "react";
import Typography from "@mui/material/Typography";

export const style = {
  fontFamily: "Arial",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: { xs: "16px", md: "18px", lg: "18px" },
  lineHeight: { xs: "25px", md: "30px", lg: "30px" },
  alignItems: "center",
  color: "#2B2A35",
  maxWidth: "1364px",
  textAlign: "justify",
  letterSpacing: "0em",
};
const Paragraph = ({ children, textAlign, ...props }) => {
  return (
    <Typography
      variant="h6"
      component="p"
      color="text.secondary"
      {...props}
      sx={{ ...style, textAlign: textAlign }}
    >
      {children}
    </Typography>
  );
};

export default Paragraph;
