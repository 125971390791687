import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "components/Container";

const Main = () => {
  const style = {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: { xs: "16px", md: "18px" },
    lineHeight: { xs: "25px", md: "30px" },

    textAlign: "center",
    display: "flex",
    alignItems: "center",

    color: "#2B2A35",
    maxWidth: "1300px",
  };
  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: {
          lg: "98px 32px 108px 32px !important",
          md: "98px 32px 108px 32px !important",
          sm: "50px 32px 50px 32px !important",
          xs: "40px 32px 40px 32px !important",
        },
      }}
    >
      <Box>
        <Typography
          variant="h6"
          component="p"
          color="text.secondary"
          sx={style}
          align={"center"}
        >
          Direct Affect was founded on the belief that we need to do better –
          that despite the importance of non-profit organizations (NPOs) to the
          US economy and how we live, NPO’s needed better tools and technology
          to embrace and manage the evolving challenges associated with
          supporter engagement. And, we were absolutely certain that those tools
          and technology needed to start with the needs of the supporter first.
          Long gone are the days when NPOs could expect that their annual or
          periodic appeal for donations would yield the results necessary for
          them to accomplish their mission - nor that the same supporters would
          respond as they always had to outdated forms of communication. To be
          successful now and in the future, NPOs need to harness the energy and
          enthusiasm that their supporters have for their cause and translate
          that energy into action and long-term involvement. The truth is
          supporters mean more to NPOs than the amount of their next donation.
          We needed to develop a platform that allowed that relationship to
          flourish - reducing the distance between NPOs and their supporters and
          eliminated the high cost and unnecessary friction associated with
          supporter engagement.
        </Typography>
        <Typography
          variant="h6"
          component="p"
          color="text.secondary"
          sx={style}
          mt={4}
          align={"center"}
        >
          The response to our efforts has been overwhelming and the impact we
          are positioned to make on philanthropic engagement is extraordinary.
          We hope you will join our journey.
        </Typography>
      </Box>
    </Container>
  );
};

export default Main;
