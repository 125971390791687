import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import logo from "assets/logo.svg";
import CustomButton from "components/CustomButton";
import Container from "components/Container";
import { useWindowSize } from "components/useWindowSize";
import { useNavigate } from "react-router-dom";

const GetStarted = ({ padding }) => {
  const { width } = useWindowSize();
  const navigate = useNavigate();

  return (
    <Container
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      padding={
        padding || {
          lg: "103px 0 58px 0 !important",
          md: "103px 0 58px 0 !important",
          sm: "28px 10px 28px 10px !important",
        }
      }
    >
      <Box sx={{ maxWidth: "745px" }}>
        <Box
          width={1}
          sx={{
            display: "flex",
            align: "center",
            justifyContent: "center",
          }}
        >
          <Box display={"flex"} component="a" href="/" title="Logo">
            <Box
              component={"img"}
              src={logo}
              loading="lazy"
              decoding={"async"}
              width="312px"
              height="70px"
              alt={"Logo"}
            />
          </Box>
        </Box>
        <Typography
          variant="h6"
          component="p"
          color="text.secondary"
          sx={{
            fontFamily: "Arial",
            fontSize: { xs: "16px", md: "18px" },
            lineHeight: { xs: "25px", md: "30px" },
            fontWeight: 400,
            textAlign: "center",

            color: "#2B2A35",
            maxWidth: { xs: "364px", sm: "745px", md: "745px" },
            padding: {
              xs: width < 380 ? "0 16px 0 16px" : "0px",
              sm: "0px",
              md: "0px",
              lg: "0px",
            },
            letterSpacing: "0em",
            marginBottom: "35px",
          }}
          align={"center"}
        >
          Direct Affect is currently accepting new non-profit organizations to
          join our rapidly expanding platform by invitation only. If your
          organization is interested in joining us, please submit your
          organization for consideration by selecting the Organization Sign-Up
          button below.
        </Typography>
        <Box
          display="flex"
          flexDirection={{ xs: "row", sm: "row" }}
          alignItems={{ xs: "stretched", sm: "flex-start" }}
          justifyContent={"center"}
          marginTop={{ md: 1, sm: 3, xs: 4 }}
        >
          <CustomButton
            component="a"
            title="User Login"
            width={width < 350 ? "100px" : "151px"}
            height="50px"
            background={"white"}
            color={"#1CAA9A"}
            borderRadius={{ sm: "5px" }}
            fontSize="15px"
            fontFamily="Arial"
            border={"1px solid #1CAA9A"}
            lineHeight="17px"
            letterSpacing="0em"
            fontWeight={400}
            href="https://admin.directaffect.net"
          />

          <CustomButton
            title="Organization Sign Up"
            color={"white"}
            borderRadius={{ sm: "5px" }}
            width={width < 350 ? "170px" : "182px"}
            height="50px"
            fontSize="15px"
            fontFamily="Arial"
            margin="0 0 0 15px"
            onClick={() => navigate("/signup")}
            lineHeight="17px"
            letterSpacing="0em"
            fontWeight={400}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default GetStarted;
