export const general = {
  alternate: {
    main: "#f7faff",
    sub: "#FCFBFB",
  },
  primary: {
    main: "#1EBF79",
    sub: "#FCFBFB",
  },
  secondary: {
    main: "#D0847D",
    sub: "#ffb74d",
  },
  text: {
    primary: "#393841",
    secondary: "rgba(0, 0, 0, 0.75);",
  },
  divider: "rgba(0, 0, 0, 0.12)",
};
