import React from "react";
import Layout from "layout/index.js";
import Main from "./components/Main/Main";
import Hero from "components/Hero/Hero";
import tosImage from "assets/tosImage.png";
import { useLocation } from "react-router-dom";

const TOS = () => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location.key]);
  return (
    <Layout hr>
      <Hero
        path={"Home / Terms of Service"}
        title={"Terms of Service"}
        lastUpdated={"Last Updated: June 5, 2024"}
        image={tosImage}
      />
      <Main />
    </Layout>
  );
};

export default TOS;
