import React from "react";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import Container from "components/Container";
import Heading from "components/Typographies/Heading";
import Paragraph from "components/Typographies/Paragraph";
import { Typography } from "@mui/material";

const Main = () => {
  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: {
          lg: "98px 32px 108px 32px !important",
          md: "98px 32px 108px 32px !important",
          sm: "0px 32px 50px 32px !important",
          xs: "0px 32px 40px 32px !important",
        },
        maxWidth: "1364px !important",
      }}
    >
      <Box>
        <Heading children={"I. Introduction"} head />
        <Paragraph
          children={
            <>
              The Direct Affect Impact Fund (“DAIF”) is a Donor Advised Fund
              account, established for the benefit of Direct Affect (“DA”)
              members. Direct Affect enables members to open and maintain their
              own Donor Advised Fund or Personal Giving Account (“DAF”) as a
              sub-account to DAIF. These accounts are established in partnership
              with The Givinga Foundation (the “Sponsor”), an independent,
              section 501(c)(3) sponsoring organization that administers
              donor-advised funds, and operates exclusively for charitable
              purposes. Consistent with Direct Affect’s corporate philosophy,
              the Sponsor’s mission is to empower donors of all sizes through a
              transparent and accountable public charity platform that provides
              access to world class giving accounts and promotes active and
              consistent giving.
              <br />
              <br />
              Direct Affect, in conjunction with Sponsor, provides users of the
              platform the programs, tools, resources, and support to make
              charitable giving simple and effective. Sponsor has selected
              Givinga, Inc. (GI) to act as the technological, administrative and
              operational partner for the collection of grant information,
              reporting, and maintenance and payment of donated funds and
              grants. Sponsor has entrusted GI to act as its agent in most of
              the due diligence processes and GI is expected to perform at all
              times according to all appropriate processes and procedures
              determined necessary.
              <br />
              <br />
              Contributions made thru the Platform are irrevocable and are
              immediately tax deductible to the fullest extent allowed by law.
              Contributions can be made at times that are most effective for the
              user from a tax or financial planning perspective, while grant
              recommendations to IRS registered 501(c)3 public charities on the
              platform may be made on a separate timetable to support the user’s
              charitable aims. We subscribe to an Assets in Motion Philosophy
              that aligns all parties around granting versus accumulating
              assets. Although Donor Advised Funds provide unprecedented
              flexibility to Account Holders and are not subject to excise
              taxes, payout rules or disclosure requirements, the platform has
              been developed to maximize Account Holder impact and is structured
              to encourage active grant making. To that end, we expect Account
              Holders to make grant recommendations of not less than 33% of the
              trailing 12-month average DAF account balance during each 12-month
              period. Failure to do so for two consecutive 12-month periods will
              result in the account being deemed inactive. In the future, assets
              may be invested and professionally managed, offering the potential
              for contributions to grow and ultimately result in greater support
              for charitable organizations.
              <br />
              <br />
              Sponsor has been recognized by the Internal Revenue Service (IRS)
              as a tax-exempt charitable organization (EIN: 47-4172718) as a
              public charity sponsoring organization, as described in sections
              501(c)(3), 509(a)(1) and 170(b)(1)(A)(vi) of the Internal Revenue
              Code (“the Code”) of 1986, as amended. The Sponsor is governed by
              an independent Board of Directors and all activities of the
              Sponsor are subject to the Directors’ discretion, directly or
              through staff or other agents.
              <br />
              <br />
              The information contained herein is general, informational in
              nature and it is not intended to be nor should be construed as
              legal or tax advice. Direct Affect does not provide legal or tax
              advice. In compliance with IRS requirements, any information
              contained herein is not intended or written to be used, and cannot
              be used, for the purpose of avoiding tax penalties under the
              Internal Revenue Code. Content provided relates to taxation at the
              federal level only. Availability of certain federal income tax
              deductions may depend on whether you itemize deductions and other
              individual factors. Rules and regulations regarding deductions for
              charitable purposes vary at the state level, and laws of a
              specific state or laws relevant to a particular situation may
              affect the applicability, accuracy, or completeness of the
              information provided.
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
          }}
        />
        <Heading children={"II. Policy Exceptions"} head />
        <Paragraph
          children={
            <>
              Exceptions to these policies may be granted by both a majority
              vote of the Sponsor Directors in office at the time the exception
              is considered and Direct Affect.
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        />
        <Heading children={"III. Financial Policies"} head />
        <Paragraph
          children={
            <>
              <b>A. The Platform</b>
              <br></br>
              The Platform offers access to a Donor Advised Fund (DAF) with
              Sponsor to assist users in designing a philanthropic giving plan
              suited to their needs and interests. Users are encouraged to
              create an annual giving goal and establish a named Account with an
              initial gift. Users are also encouraged to authorize an automatic
              (weekly, monthly or quarterly) giving schedule in order to meet
              their giving goal. Donor Advised Funds allow the donor to create a
              fund without naming any specific charitable institutions and
              retaining the right to recommend grants to charitable
              organizations of his or her choice over time. However, since the
              Sponsor owns all funds, any such recommendations shall be solely
              advisory, and under no circumstances shall the Foundation be bound
              by any such advice or recommendation. It is the policy of the
              Sponsor to approve all grant recommendations, provided the grant
              complies with all state and federal laws. In addition to the
              donor, the fund can be advised by a person or advisory committee
              named by the donor. Advisors are subject to the Advisor Succession
              Policy. All recommended grants from Donor Advised Funds are
              evaluated to ensure that all recommended recipients are legitimate
              charities.
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        />

        <Paragraph
          children={
            <>
              <b>B. Creating a Donor Advised Fund Account</b>
              <br />
              <br />
              <ul style={{ paddingLeft: "25px", margin: "0px" }}>
                <li>
                  <b>Eligibility to Establish an Account</b>
                </li>
              </ul>
              Individuals, corporations and other business entities, trusts and
              estates, as registered users on the platform are eligible to
              establish a charitable giving account on the Platform, also
              referred to as an “Account.” However, charitable organizations
              exempt under section 501(c)(3) of the Internal Revenue Code, such
              as private foundations, are not eligible to establish an Account.
              <br />
              <br />
              <ul style={{ paddingLeft: "25px", margin: "0px" }}>
                <li>
                  <b>To Establish an Account</b>
                </li>
              </ul>
              An Account is established for Individuals, trusts and estates at
              the time they register on the Direct Affect platform. Account
              Holders may make contributions at any time via the Direct Affect
              mobile App. Per our Gift Acceptance Policy, we reserve the right
              to reject an account in its sole discretion.
              <br />
              <br />
              <ul style={{ paddingLeft: "25px", margin: "0px" }}>
                <li>
                  <b>Naming the Account</b>
                </li>
              </ul>
              Account Holders are encouraged to name their personal giving
              account. Until modified by the Account Holder, the default name
              will be “The , , Giving Fund.”
              <br />
              <br />
              <ul style={{ paddingLeft: "25px", margin: "0px" }}>
                <li>
                  <b>Naming a Primary Account Holder</b>
                </li>
              </ul>
              The Primary Account Holder is the individual who is a registered
              Direct Affect user and has contributed to their Donor Advised
              Fund. That that user has primary responsibility for the
              relationship with Direct Affect and Sponsor. All Account
              correspondence, with the exception of Tax receipts related to
              contributions made by Additional Account Holders (as described
              below) and/or third-party contributors, will be accessible by the
              Primary Account Holder via the App.
              <br />
              <br />
              <ul style={{ paddingLeft: "25px", margin: "0px" }}>
                <li>
                  <b>Additional Account Holders</b>
                </li>
              </ul>
              Primary Account Holders may name up to three other persons
              (“Additional Account Holders”), at least 18 years old, each of
              which will have full and equal privileges to recommend grants, but
              may not name and remove successors to the Account, or name and
              remove Additional Account Holders. Primary Account Holders must
              provide written consent to the addition or removal of Additional
              Account Holders. Additional Account Holders will have access to
              confirmations of their individual contributions to Sponsor for tax
              purposes. Additional Account Holders do not receive grant
              confirmations, even of grants they recommended.
              <br />
              <br />
              <ul style={{ paddingLeft: "25px", margin: "0px" }}>
                <li>
                  <b>Tax Implications</b>
                </li>
              </ul>
              Because Sponsor is a public charity under Sections 501(c)(3) and
              170(b)(1)(a) of the Internal Revenue Code, initial and subsequent
              contributions made to its Donor Advised Funds currently receive
              the maximum charitable deduction permitted under state and federal
              law. Each gift to Sponsor is acknowledged with an electronic
              receipt confirming the date and a description of the gift for the
              donor’s tax records. Note: Sponsor is not a licensed tax advisor.
              Donors should work with their financial, tax or legal professional
              to determine the best charitable giving strategy for their
              situation.
              <br />
              <br />
              <ul style={{ paddingLeft: "25px", margin: "0px" }}>
                <li>
                  <b>Control of Assets / Material Restrictions</b>
                </li>
              </ul>
              Every Account at the Sponsor is a Donor Advised Fund. A
              fundamental characteristic of Donor Advised Funds is the transfer
              of donor control to the public charity. When you make a gift to
              establish an Account or to add to your Account, the gift becomes
              the property of Sponsor, which means that the Foundation has
              exclusive legal control over the contributed assets. This means
              that while you, as an advisor, may recommend grants, the
              Foundation is not legally bound by your advice. However, if a
              grant recommendation meets the requirements contained herein,
              Sponsor will approve your recommendation.
              <br />
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        />
        <Paragraph
          children={
            <>
              <b>C. Fees</b>
              <br></br>
              The Platform operates under an “Assets in Motion” philosophy and
              is designed to align the interests of all parties involved in
              promoting active and consistent giving across the platform. To
              encourage all parties to focus on donating versus accumulating, DA
              and Sponsor have established the following fee schedules:
              <br />
              <br />
              <b>Start Up Fee</b>
              <ul style={{ marginTop: "0px", paddingLeft: "25px" }}>
                <li>
                  There are no fees associated with creating an Account on the
                  platform
                </li>
                <li>
                  $0 for any Donations made to your Account through the DA App.
                </li>
              </ul>
              <b>Annual Administrative Fee</b>
              <ul style={{ marginTop: "0px", paddingLeft: "25px" }}>
                <li>
                  $0. There are no annual Administration Fees and no fees based
                  on assets under management.
                </li>
                <li>
                  The GF charges no additional fees for internal investment
                  oversight.
                </li>
              </ul>
              <b>Transaction Fees</b>
              <ul style={{ marginTop: "0px", paddingLeft: "25px" }}>
                <li>$0. There will be no fee for donations made via ACH.</li>
                <li>
                  If a donor is funding their account with a credit card, the
                  donor has the option of adding the processing fee to the
                  donation amount (currently 2.9% +$0.30 for Visa and Mastercard
                  transactions and 3.5% for American Express transactions)
                  allowing the entire donation to be credited to their Personal
                  Giving Account.
                </li>
                <li>
                  Donations made by in a currency other than USD will incur a 1%
                  conversion fee.
                </li>
              </ul>
              <b>Grant Fees</b>
              <ul style={{ marginTop: "0px", paddingLeft: "25px" }}>
                <li>
                  $0 There will be no fee for any Grant made by an Account
                  Holder to a charitable organization within the USA.
                </li>
              </ul>
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        />
        <Paragraph
          children={
            <>
              <b>D. Account Holder Succession</b>
              <br></br>
              Additional Account Holders to a Donor Advised Fund may be named by
              the user subsequent to the time the Account is established. The
              Account Holder may name an advisor to succeed him or her upon his
              or her death or resignation. The Account Holder must identify his
              or her successor by name (i.e. "John Smith," not "my child") and
              provide the successor's most recent email address. Each succeeding
              advisor may also identify in writing a subsequent successor in the
              same manner. If no written notification of a successor advisor has
              been received by Direct Affect and Sponsor during the life of the
              Account Holder and no indication within 6 months of the Account
              Holder’s death that a successor was named in a will, Sponsor will
              distribute all remaining assets in the Account, prorata, to the
              charities that the Account Holder had granted gifts to during the
              12 months prior to the Account Holders death.
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        />
        <Paragraph
          children={
            <>
              <b>E. Gift Acceptance</b>
              <br />
              <br />
              <b>Overview:</b>
              <br />
              It is intent of Direct Affect and Sponsor to offer users the
              opportunity to make gifts to the Sponsor, both cash and non-cash
              assets, in a manner beneficial to users while protecting the
              fiscal and legal integrity Sponsor. Sponsor reserves the right to
              refuse any gift that it believes is not in the best interests of
              Sponsor or the user, including but not limited to a gift that in
              any way may jeopardize the tax-exempt status of the Sponsor. Once
              a gift is transferred to the Donor Advised Fund, it becomes the
              property of Sponsor. Direct Affect and Sponsor do not authorize
              the acceptance of gifts that would jeopardize the financial,
              legal, or moral integrity or reputation of Direct Affect or
              Sponsor. Full responsibility rests with the donor for claiming any
              income tax deductions, including filing any tax form(s) required
              under state or federal law. Full responsibility also rests with
              the donor for the value given to tangible personal property or
              services. Direct Affect does not give legal or tax advice. In
              conformance with Treasury Department regulations, gifts to Sponsor
              may not be directly or indirectly subjected by a donor to any
              material restriction or condition that prevents the Foundation
              from freely and effectively employing the transferred assets, or
              the income derived therefrom, in furtherance of its exempt
              purposes. All gifts, other than cash or marketable securities,
              will require review and acceptance by Direct Affect and Sponsor
              and may be declined for any reason.
              <br />
              <br />
              <b>Direct Affect and Sponsor accept (or intend to accept):</b>
              <br />
              <ul style={{ marginTop: "0px", paddingLeft: "25px" }}>
                <li>Gifts via ACH, Credit Card and Apple Pay</li>
                <li>Gifts via Check, money order or bank draft</li>
                <li>
                  Gifts of Marketable Securities including publicly traded
                  stocks and bonds.
                </li>
                <li>Gifts of Mutual Funds</li>
                <li>
                  Gifts of Securities in Closely Held and S-Corporations
                  (subject to review and approval)
                </li>
                <li>Gifts of Other Assets (subject to review and approval)</li>
              </ul>
              <b>Costs of Accepting and Administering Gifts</b>
              <br />
              The costs associated with the transfer of a gift by the donor,
              such as attorney fees, accounting fees, and other professional
              fees as well as other costs including, but not limited to,
              appraisal, escrow, evaluation, title insurance, and environmental
              assessment fees are the responsibility of the donor. Custodial,
              investment, and administrative fees shall be assessed and paid
              from the respective funds in accordance with the Foundation’s
              guidelines and fee schedules as modified from time to time.
              <br />
              <br />
              <b>Excess Business Holdings</b>
              <br />
              A user’s account (as described below) may not hold more than a
              minor interest in a business when the account Holder or a related
              party (“disqualified person”) also holds an interest in the
              business. This is known as the “excess business holdings” rule,
              which prohibits the account and disqualified persons from together
              holding more than a 20% interest in a business enterprise (20% of
              the voting stock of an incorporated business or 20% of the profits
              interest of a partnership or joint venture) or any interest in a
              sole proprietorship or unincorporated entity. Direct Affect and
              Sponsor will identify any potential gift that would qualify as an
              excess business holding and will notify the prospective donor. GF
              will dispose of any excess business holding as quickly as
              possible, but no later than after the five-year legal limit for
              doing so.
              <br />
              <br />
              <b>Gift Acknowledgement</b>
              <br />
              Sponsor will acknowledge, in writing, the receipt of all gifts in
              a manner that satisfies the substantiation requirements of the
              Internal Revenue Code (“IRC”) section 170. Such acknowledgement
              shall be available via the DA App.
              <br />
              <br />
              <b>Third-Party Contributions</b>
              <br />
              Non-Account Holders may make contributions to any Individual or
              Campaign Account via the APP using ACH or Credit Card. The
              specific Account or Campaign name should be listed on the memo
              line. All contributors will receive a gift acknowledgement via the
              App for confirmation of the tax deductibility of their gift.
              <br />
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        />
        <Paragraph
          children={
            <>
              <b>F. Investment Policy Statement</b>
              <br />
              <br />
              <b>Purpose</b>
              <br />
              Sponsor has adopted an Investment Policy Statement to establish
              its investment objectives and philosophy. While shorter-term
              investment results will be monitored, adherence to a sound
              long-term investment policy, which balances short-term needs with
              preservation of the real (inflation-adjusted) value of assets, is
              crucial to the long-term success of Sponsor.
              <br />
              <br />
              <b>Scope</b>
              <br />
              This Investment Policy applies to all assets that are included in
              Sponsor investment portfolio. The Board has authorized an
              Investment Committee to oversee the portfolio.
              <br />
              <br />
              <b>Fiduciary Duty</b>
              <br />
              In seeking to attain the investment objectives set forth in this
              Policy, the Investment Committee and its members shall exercise
              prudence and appropriate care in accordance with the Prudent
              Investor Rule. All investment actions and decisions must be based
              solely in the interest of Sponsor. All of the Sponsor assets will
              be invested in a manner consistent with state law and Sponsor’s
              governing instruments. Any Investment Committee member who
              believes he or she may have a potential conflict of interest must
              provide full and fair disclosure to the Investment Committee of
              all material facts regarding such potential conflict.
              <br />
              <br />
              <b>Definition of Duties</b>
              <br />
              The Board of Directors of Sponsor has the ultimate fiduciary
              responsibility for Sponsor’s investment portfolio. The Board must
              ensure that appropriate policies governing the management of
              Sponsor are in place and that these policies are being effectively
              implemented. To implement these responsibilities, the Board sets
              and approves the Investment Policy Statement and delegates
              responsibility to the Investment Committee for implementation and
              ongoing monitoring of the investment portfolio.
              <br />
              <br />
              The Investment Committee of the Board, which shall serve as the
              Investment Committee for Sponsor, is responsible for implementing
              the Investment Policy. This responsibility includes developing
              investment strategy, hiring and firing of investment managers,
              custodians and investment consultants, monitoring performance of
              the investment portfolio on a regular basis (at least
              semiannually), and maintaining sufficient knowledge about the
              portfolio and its managers so as to be reasonably assured of their
              compliance with the Investment Policy Statement.
              <br />
              <br />
              The Investment Committee is responsible for managing and
              overseeing Sponsor’s investment portfolios. The Investment
              Committee assumes the following responsibilities as they pertain
              to:
              <br />
              <br />
              <b>i. Statement of Investment Objectives and Policies</b>
              <br />
              Make recommendations to the Board, when deemed necessary, as to
              changes in the objectives, guidelines, or standards, based upon
              material and sustained changes in the capital markets, or changes
              in the goals of Sponsor.
              <br />
              <br />
              <b>ii. Asset Allocation</b>
              <br />
              Make recommendations to the Board, when deemed necessary, as to
              the appropriate portfolio weightings among the various major asset
              classes (e.g., stocks, bonds, and cash) and sub-asset classes
              (e.g., domestic vs. international, large cap vs. small cap, growth
              vs. value, etc.).
              <br />
              <br />
              <b>iii. Distribution Policy</b>
              <br />
              Review and make recommendations to the Board as needed with regard
              to an appropriate distribution rate policy.
              <br />
              <br />
              <b>iv. Selection of Investment Managers or Vehicles</b>
              <br />
              Implement a selection process by identifying and screening
              candidates and vehicles for appropriate portfolio and
              organizational characteristics. This will include due diligence
              checks, quantitative measurement of expected risk/return
              relationship among various alternatives, participating in the
              interview process and serving as an overall information-gathering
              conduit for the Board and the President (including drafting and
              summarization of requests for proposals).
              <br />
              <br />
              <b>v. Low-Fee Index Fund Option</b>
              <br />
              The Investment Committee is authorized to establish index fund
              accounts, if deemed prudent and desirable.
              <br />
              <br />
              <b>vi. Monitor Investment Performance</b>
              <br />
              A performance evaluation of the portfolios and their component
              parts will be conducted quarterly. A report will be provided to
              the Board following the close of the quarter. The President of
              Sponsor has daily responsibility for administration of Sponsor’s
              investment portfolio and will monitor the performance of the
              investment portfolio on a regular basis (at least quarterly). In
              addition, the President will consult with the Investment Committee
              and Board on all matters relating to the investment of Sponsor’s
              portfolio and will serve as primary contact for Sponsor’s
              investment managers, investment consultants, and custodians.
              <br />
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        />
        <Paragraph
          children={
            <>
              <b>G. Grant Distributions</b>
              <br />
              <br />
              <b>How to Recommend a Grant</b>
              <br />
              Grants from your Account may be recommended via the App. You can
              also check grant status, account balances, see grant-making and
              gift history, research, and link your giving with your employer.
              <br />
              <br />
              <b>Minimum Grant Amount</b>
              <br />
              The minimum amount for which User may request a grant from Sponsor
              is $5.
              <br />
              <br />
              There is no maximum grant amount limit as long as the activities
              of the Account remain in compliance with the Distributions Policy
              of Sponsor. There is no limit on the number of grants which may be
              issued in a year from any single Account as long as the activities
              of the Account remain in compliance with all other policies and
              procedures of Sponsor.
              <br />
              <br />
              <b>Payment of Grants</b>
              <br />
              Any grant recommendations to approved charities are paid monthly,
              pending authorization. Grants are paid through the Automated
              Clearing House (ACH) for electronic payment and are subject to a
              1% fee. Sponsor reserves the right to delay payments to any
              non-approved charity until the approval process is completed.
              <br />
              <br />
              All approved grants will be communicated to the grant recipient
              via the Platform, announcing the grant award and identifying the
              member. Grants from Donor Advised Funds may be made to tax-exempt
              registered 501(c)3 public charities. When a grant is issued from a
              Donor Advised Fund, notification will be made to the Primary
              Account Holder. Funds will be transferred from the Donor Advised
              Fund at the time the grant request is made. Grants will be made
              consistent with the instructions given by the Primary Account
              Holder. If, however, Sponsor believes the instructions are
              contrary to the best interests of Sponsor or the user, then the
              instructions shall be modified by Sponsor.
              <br />
              <br />
              To the extent practicable or feasible, Sponsor shall distribute
              amounts for purposes that are consistent with the Primary Account
              Holder’s charitable interests. The Sponsor is vested with ultimate
              authority and control over the principal and income of each Fund.
              Any distribution shall identify the name of the Fund from which it
              is made.
              <br />
              <br />
              <b>Inactive Accounts</b>
              <br />
              An Account is deemed inactive if:
              <br />
              <ul style={{ marginTop: "0px", paddingLeft: "25px" }}>
                <li>
                  The Primary Account Holder dies or resigns or evidence of his
                  or her incapacity is provided to the Foundation, and if no
                  successor advisor has been named.
                </li>
                <li>
                  All named successor advisors are unable or unwilling to serve
                  as such or are otherwise unresponsive to reasonable attempts
                  to contact them.
                </li>
                <li>
                  Account Holders fail to make grant recommendations of at least
                  33% of the trailing 12-month average DAF account balance
                  during two consecutive 12-month periods.
                </li>
              </ul>
              <br />
              If the Account becomes inactive, Sponsor will deem the advisory
              period to have ended and will initiate distributions from the
              Account in accordance with the provisions of the Account
              agreement.
              <br />
              <br />
              <b>Termination</b>
              <br />
              Upon the death, resignation or incapacity of the last advisor to
              the Account, or if the Account is determined to be inactive, the
              assets of the Account will become a part of Sponsor’s unrestricted
              funds and Sponsor will be solely responsible for disbursement.
              <br />
              <br />
              <b>Transfers</b>
              <br />
              No party to a fund may transfer or assign an Account or its
              obligations to a third party without the prior written approval.
              <br />
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        />
        <Paragraph
          children={
            <>
              <b>H. Charitable Recipients</b>
              <br />
              <br />
              <b>Eligible Grantees</b>
              <br />
              <ul style={{ marginTop: "0px", paddingLeft: "25px" }}>
                <li>
                  501(c)(3) public charities*, public and private schools,
                  municipalities, government entities and religious
                  organizations anywhere in the United States (this includes the
                  U.S. arm of any international organization, provided the local
                  arm qualifies under these rules) that have been certified by
                  Charity Navigator or approved directly by Sponsor.
                </li>
                <li>
                  Private operating foundations (provided the private foundation
                  was not established by the advised fund holder or his/her
                  family and is not a Foundation established by employees
                  Sponsor).
                </li>
                <li>
                  Individuals affected by disaster as described in IRS Section
                  139.
                </li>
              </ul>
              <br />
              <b>Prohibited Grantees</b>
              <br />
              <ul style={{ marginTop: "0px", paddingLeft: "25px" }}>
                <li>Non-501(c)(3) organizations or foreign equivalents.</li>
                <li>Any entity if the purpose is not charitable.</li>
                <li>
                  Any entity to satisfy legally binding pledges of the donor,
                  advisor, or related parties.
                </li>
                <li>
                  Any entity from which the donor, advisor or related parties
                  will receive more than incidental benefits.
                </li>
                <li>
                  Any entity for the purpose of supporting a political campaign.
                </li>
                <li>Private non-operating foundations.</li>
                <li>Private Operating Foundations of employees of Sponsor.</li>
                <li>
                  The fund’s donor, advisor or related parties in the form of a
                  direct grant, loan, compensation, or similar payment.
                </li>
              </ul>
              <br />
              <b>Ineligible Grantees</b>
              <br />
              *Please note: a very small number of 501(c)(3) public charities
              are ineligible due to IRS regulations concerning Donor Advised
              Funds. As such, grants to the following types of organizations
              will typically not be made:
              <ul>
                <li>
                  Supporting Organizations under section 509(a)(3) of the
                  Internal Revenue Code.
                </li>
                <li>
                  Churches and houses of worship that have not been issued a
                  tax-exemption letter by the Treasury Department, unless they
                  operate under an umbrella exemption letter.
                </li>
                <li>
                  Public safety organizations under section 509(a)(4) of the
                  Internal Revenue Code.
                </li>
                <li>
                  For-profit organizations, even if limited to charitable
                  purposes.
                </li>
                <li>Private non-operating foundations.</li>
              </ul>
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        />
        <Paragraph
          children={
            <>
              <b>I. Charitable Recipient Due Diligence</b>
              <br></br>
              Sponsor, in association with its partner(s), performs due
              diligence procedures on each charitable organization or qualified
              business or individual granted funds by Sponsor, including grants
              made by an Account Holder from a donor advised fund (DAF). Sponsor
              only makes grants to charities, comparable charitable
              organizations, and qualified businesses or individuals in
              accordance with its policies and exercises full authority and
              control over the making of every donor advised fund grant.
              <br />
              <br />
              Sponsor anticipates that its due diligence procedures will provide
              adequate risk mitigation for more than 90% of its grants made each
              year. Due diligence includes:
              <ul style={{ marginTop: "0px", paddingLeft: "25px" }}>
                <li>
                  Verification that a charity is included in the IRS’s Business
                  Master File,
                </li>
                <li>
                  Verification that a charity is included in IRS Publication 78,
                </li>
                <li>
                  Incorporation of weekly updates from the Internal Revenue
                  Bulletin,
                </li>
                <li>
                  Identification of the charity’s public charity classification,
                </li>
                <li>
                  Identification of whether the charity is classified as an IRC
                  §509(a)(3) supporting organization
                </li>
                <li>the result of OFAC verification.</li>
                <li>
                  Rating and ranking each charity using a sophisticated
                  database.
                </li>
              </ul>
              <br />
              <b>
                Churches, Governmental Units and Other Qualified U.S. Charities
              </b>
              <br />
              Certain otherwise qualified U.S. charities are not vetted by TPPs,
              either because they are not required to register with the IRS or
              because their registrations are in process. Where this is the
              case, Sponsor’s policy is to conduct an increased level of
              scrutiny before approving or denying a proposed grant.
              <br />
              <br />
              <b>● Churches and Other Religious Institutions</b>
              <br />
              Most churches and other religious institutions do not register
              with the IRS. Nevertheless, they are generally qualified as a
              permissible charitable grant recipient under IRC §§170(c)(2)(B)
              and 170(b)(1)(A)(i). When a religious institution is the proposed
              grant recipient and cannot be vetted by its partners, Sponsor
              performs reasonable additional procedures to verify its status as
              a religious institution. These procedures may include research of
              the organization’s internet website, finding the organizations on
              a third-party authored directory or registry of churches or
              religious institutions and/or obtaining documentation directly
              from the institution.
              <br />
              <br />
              <b>● Governmental Units</b>
              <br />
              Most churches and other religious institutions do not register
              with the IRS. Nevertheless, they are generally qualified as a
              permissible charitable grant recipient under IRC §§170(c)(2)(B)
              and 170(b)(1)(A)(i). When a religious institution is the proposed
              grant recipient and cannot be vetted by its partners, Sponsor
              performs reasonable additional procedures to verify its status as
              a religious institution. These procedures may include research of
              the organization’s internet website, finding the organizations on
              a third-party authored directory or registry of churches or
              religious institutions and/or obtaining documentation directly
              from the institution.
              <br />
              <br />
              <b>Validation</b>
              <br />
              An NPO on the Platform must be a registered 501(c)(3) public
              charity as defined in section 509(a)(1) and/or section
              170(b)(1)(a)(vi) or section 509(a)(2) of the Code. Such
              organizations include governmental units, but only if the grant is
              made for exclusively public purposes; organizations operated
              exclusively for religious, charitable, scientific, literary, or
              educational purposes, such as schools, medical institutions, and
              publicly supported organizations. Before any grant is issued, the
              Sponsor will use due diligence to ensure the validity of an
              organization’s current tax-exempt status. If evidence of an
              organization’s 501(c)(3) status or equivalent status is not on
              file at Sponsor, a copy of this documentation must be obtained
              before a grant is issued.
              <br />
              <br />
              In general, Sponsor’s policies and procedures for grant due
              diligence are designed to assess the risk associated with:
              <br />
              <b>A. Identity of Grant Recipient</b>
              <br />
              Sponsor implements a risk-based approach to determine the type and
              degree of due diligence to perform for each grant recipient.
              Sponsor’s risk-based approach distinguishes between U.S. charities
              that self- identify that their activities are predominantly
              U.S.-based, and U.S. charities that self- identify that their
              activities are predominantly to pass-through to foreign based
              charities and foreign based charities.
              <br />
              <br />
              Grant recipients may be disqualified for a number of reasons.
              Examples of disqualified grant recipients include non501(c)(3)
              exempt organizations, private non-operating Foundations, Type III
              non-functionally integrated supporting organizations,
              organizations that are not yet recognized by the IRS,
              organizations that otherwise fail the GF’s due diligence
              procedures and organizations that appear on a watch list such as
              the list of Specially Designated Nationals (SDN) maintained by the
              Office of Foreign Asset Control (OFAC), foreign based charities.
              <br />
              <br />
              <b>B. Amount of the Grant</b>
              <br />
              Grant requests in excess of $10,000 are subject to additional
              review and approval. All Grants at any amount less than $10,000
              will follow an authorized due diligence process.
              <br />
              <br />
              <b>C. Charitable Purpose of the Grant</b>
              <br />
              Sponsor implements procedures designed to ensure that no grant is
              made for a disqualifying charitable purpose. Sponsor implements
              these procedures in order to maintain its exempt status as a
              charitable sponsor of donor-advised funds and to avoid the
              imposition of excise taxes on the Fund, its officers and Account
              Holders.
              <br />
              <br />
              Grants may be made in honor or in memory of a loved one or to
              support specific programs maintained by a qualified charity.
              However, grants may not be made to benefit an Account Holder or
              family member; pay for dues, membership fees, tuition, goods from
              a charitable auction or other goods and services; support a
              political campaign or lobbying activity; or to fulfill an
              existing, binding pledge. In addition, the GF does not reimburse
              disqualified persons for expenditures made on behalf of a
              donor-advised fund.
              <br />
              <br />
              When an Account Holder recommends a grant, the Account Holder
              certifies that the grant is not for a disqualifying purpose.
              Further, the GF reviews each grant’s charitable purpose as one of
              several items in its grantmaking due diligence procedures.
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        />
        <Paragraph
          children={
            <>
              <b>J. Grant Restrictions</b>
              <br />
              Grants must NOT provide a financial benefit to The Primary Account
              Holder.
              <br />
              <br />
              Sponsor will not make a grant that provides a financial benefit to
              Account Holder, or any third-party contributor. Distributions from
              the Sponsor may not be used in whole or in part to satisfy any
              pre-existing legally binding pledge or for any private benefit
              such as dues, membership fees, benefit tickets or tables at
              fund-raising dinners, or goods and services bought at charitable
              auctions. Sponsor may make grants that provide a Donor, Donor
              Advisor(s), Advisory Committee member or related party with name
              recognition and such other incidental benefits as permitted by the
              Internal Revenue Service. Sponsor will not make any grant, loan,
              compensation or similar payment (including expense reimbursement)
              to an Account Holder, Advisory Committee member, any person in
              whose honor a Fund is created or named or any related party from
              any fund that is classified as a donor advised fund under the law.
              Sponsor will also not make any grant directly to an individual or
              to an organization for the benefit of a specified individual from
              any fund that is classified as a donor advised fund under the law.
            </>
          }
        />
        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        />
        <Typography
          style={{
            fontFamily: "Century Gothic",
            fontStyle: "normal",
            fontWeight: 400,
          }}
          sx={{
            fontSize: { xs: "28px", lg:"44px", md: "46px" },
            lineHeight: { xs: "40px", lg: "50px", md: "50px" },
          }}
        >
          A Word About Pledges and Benefits
        </Typography>
        <Paragraph
          children={
            <>
              <br />
              <b>PLEDGES</b>
              <br />
              The IRS does not allow Donor Advised Funds to be used to fulfill
              an enforceable pledge made to an organization. What constitutes an
              enforceable pledge? It occurs when a donor makes a financial
              commitment in writing to an organization. The donor is then
              legally responsible for fulfilling that commitment. From the
              perspective of the IRS, using a Donor Advised Fund to satisfy that
              commitment results in an impermissible benefit—as if the donor
              were using the fund to satisfy a personal debt, such as paying a
              credit card bill or making a mortgage payment. By ensuring that
              Account holders, family members of Account holders and entities
              controlled by Account holders do not use grants to satisfy their
              enforceable pledges, the Foundation protects you from paying
              IRS-imposed excise taxes equal to 125 percent of the benefit
              received (i.e. the pledged amount). For example, a legally binding
              pledge of $500 paid by making a grant from a DAF Account would be
              subject to an additional $625 excise tax. The Sponsor could also
              be subject to a variety of excise taxes related to the grant.
              <br />
              <br />
              <b>MULTI-YEAR GRANTS</b>
              <br />
              Future services will allow Account Holders to set-up multiyear
              grants from their Donor Advised Fund payable to the organization
              of your choosing. Once the ability to arrange these with Sponsor
              is available, we will be happy to notify the organization of the
              Foundation’s commitment on your behalf. Account Holders will be
              notified when this service is available.
              <br />
              <br />
              <b>MATERIAL AND INCIDENTAL BENEFITS</b>
              <br />
              Because donors receive charitable tax deductions for all gifts to
              a Donor Advised Fund, the IRS does not allow additional material
              benefits, such as free admission to grantee events, a discount at
              its retail shops or a table at a benefit or fundraiser for the
              organization. Allowable incidental benefits, however, can include
              coffee mugs, key chains, newsletters, snacks, etc. (if the value
              does not exceed the IRS regulations of $72 per person*) or
              anything that would not reduce the charitable benefit received.
              *As of January 2016
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        />
        <Paragraph
          children={
            <>
              <b>K. Regulatory Environment</b>
              <br />
              <br />
              <b>A. Federal Charitable Statutes and Regulations</b>
              <br />
              Sponsor makes grants to qualifying organizations described in IRC
              §170(c)(2)(B) and IRC §501(c)(3). but does not make grants to
              private non-operating foundations or non-functionally integrated
              supporting organizations.
              <br />
              <br />
              Sponsor does not make taxable distributions as defined in IRC
              §4966(c). As such, Sponsor does not make grants to individuals nor
              does Sponsor make grants to organizations for a purpose not
              described in IRC §170(c)(2)(B) unless the grant is made subject to
              the expenditure responsibility requirements of IRC §4945(h).
              <br />
              <br />
              In accordance with IRC §4967, Sponsor does not make payments to
              any person listed in IRC §4958(f)(7) that would be considered a
              prohibited benefit.
              <br />
              <br />
              Sponsor implements these safeguards in order to maintain its
              exempt status as a Sponsoring Charity of donor-advised funds and
              to avoid the imposition of excise taxes on Sponsor, its officers
              and Account Holders.
              <br />
              <br />
              <b>B. Anti-Money Laundering / USA Patriot Act</b>
              <br />
              To assist grant making charities with compliance with Anti-Money
              Laundering statutes and the USA Patriot Act, the Treasury
              Department issued Anti- Terrorist Financing Guidelines: Voluntary
              Best Practices For U.S.-Based Charities (Voluntary Best Practices)
              in December of 2005.
              <br />
              <br />
              While Treasury’s guidelines are voluntary, Section VI of the
              Voluntary Best Practices contains recommended practices that
              charities may consider before making a charitable grant. Sponsor
              has developed its Grant making Due Diligence process based on
              these Voluntary Best Practices.
              <br />
              <br />
              Sponsor vets its own officers and directors against the OFAC SDN
              list when appointed.
              <br />
              <br />
              If Sponsor uncovers an organization or other party that is
              suspected of activity relating to terrorism, Sponsor will take
              appropriate due diligence steps to determine if the match is
              valid. These steps may include the steps and further guidance
              available on OFAC’s Web site at
              <span style={{ lineBreak: "anywhere" }}>
              &nbsp; www.treas.gov/offices/enforcement/ofac/faq/answer.shtml#hotline.
              </span>
              <br />
              <br />
              Sponsor will report to the proper authorities any suspicious
              activity relating to terrorism.
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        />
        <Paragraph
          children={
            <>
              <b>L. Other Considerations</b>
              <br></br>
              Under the Internal Revenue Code (“the Code”), deductions for
              charitable contributions are subject to certain “percentage
              limitations” that limit the deductions that can be taken to a
              stated percentage of adjusted gross income (“AGI”) in the year the
              deduction is taken.
              <br />
              <br />
              (Deductions in excess of these percentage limitations may be
              carried forward up to five subsequent years.) Because Sponsor is a
              public charity, the percentage limitations that apply are
              generally the most favorable charitable deductions available under
              IRS rules.
              <br />
              <br />
              Deductions for contributions of long-term capital gain property
              (such as appreciated securities held for more than one year) may
              be taken. Deductions for most other contributions (including
              contributions of short-term capital gain property and cash
              equivalents) may be taken up to 50% of AGI. Your ability to take
              itemized deductions may be subject to certain other limitations.
              Please contact your tax advisor to determine your tax
              deductibility limits.
              <br />
              <br />
              <b>
                Tax treatment of Sponsor income and of grants made to charitable
                organizations
              </b>
              <br />
              Any income that accrues to donated funds in an Account is income
              of the sponsor, not of the Account Holder. Therefore, the Account
              Holder is neither subject to tax on that income nor eligible to
              take further charitable contribution deductions with respect to
              that income. Income or loss to Sponsor will be reflected in the
              value of each Account. When the Sponsor makes grants to charities
              based on recommendations, the Sponsor is granting its own assets.
              Accordingly, Account Holders are not eligible for additional
              charitable deductions for these grants (Any charitable
              contribution deduction would have been available at the time of
              the contribution to the Sponsor). If an Account Holder receives a
              tax receipt from the grant recipient in connection with a grant
              from Sponsor, it may not be used for tax purposes. Account Holders
              may recommend how funds in an Account are allocated among one or a
              combination of the available investment options. When no
              investment allocation is recommended, the proceeds may be invested
              in a combination of interest-bearing short-term accounts and low
              fee short term income funds. Once an Account has been established,
              the Sponsor may request that the donor make an investment
              allocation recommendation each time an additional contribution is
              made. If no allocation is recommended at the time an additional
              contribution is made to the Sponsor, the proceeds will be invested
              to proportionately reflect the allocation of the Account’s current
              balance.
              <br />
              <br />
              <b>Disputes</b>
              <br />
              DA Accounts are governed by the laws of the State of without
              regard to its conflicts of law principles. Venue for any disputes
              arising out of or in connection with DA shall be sited in the
              federal or state courts of California.
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        />
        <Typography
          style={{
            fontFamily: "Century Gothic",
            fontStyle: "normal",
            fontWeight: 400,
          }}
          sx={{
            fontSize: { xs: "28px", lg:"44px", md: "46px" },
            lineHeight: { xs: "40px", lg: "50px", md: "50px" },
          }}
        >
          Frequently Asked Questions
        </Typography>
        <Paragraph
          children={
            <>
              <Typography>
                <br />
                <b>Q: How many grants may I recommend in a year?</b>
                <br />
                <b>A:</b> You may recommend as many grants as you wish,
                amounting to the total of your fund balance. It should be noted
                that there is no minimum balance required to maintain an
                Account, however we strongly encourage you to establish an
                annual charitable budget and to subscribe to periodic funding of
                your giving account.
                <br />
                <br />
              </Typography>

              <Typography>
                <b>
                  Q: Who can recommend grants from an Account I established?
                </b>
                <br />
                <b>A:</b> In addition to you, anyone you identify as a fund
                advisor on Account may be able to recommend grants from the
                fund. This feature is intended to be added to the App in the new
                future. Once implemented, you will be able to add an advisor to
                your account in the DirectAffect App. An advisor must at least18
                years of age.
                <br />
                <br />
              </Typography>

              <Typography>
                <b>
                  Q: Can I ever change the name of the Account I established?
                </b>
                <br />
                <b>A:</b> Yes. The Account Holder can change the name of any
                Account. The Account name can be changed in the Direct Affect
                App.
                <br />
                <br />
              </Typography>

              <Typography>
                <b>
                  Q: Can the App help me identify causes that match my
                  interests?
                </b>
                <br />
                <b>A:</b> Yes. Through the App, information on a wide variety of
                nationwide NPOs is available.
                <br />
                <br />
              </Typography>

              <Typography>
                <b>
                  Q: May I serve on the board of an organization and recommend a
                  grant to it from my Account?
                </b>
                <br />
                <b>A:</b> Yes, you may make Donor Advised Fund grants to
                organizations in which you, your spouse or family members
                participate in a volunteer or board capacity. By making a grant
                request, you certify that you will receive no personal benefit.
                <br />
                <br />
              </Typography>

              <Typography>
                <b>
                  Q: I would like to buy a table at a charity dinner. May I do
                  so from my Account?
                </b>
                <br />
                <b>A:</b> No. Under the Pension Protection Act of 2006, you may
                not support fundraising events for nonprofit organizations
                through your Donor Advised Fund if you are attending the event.
                Instead you will have to pay the ticket price directly from your
                own pocket and cannot recommend even a portion of the support
                for the event from your fund. Bifurcation of grants (splitting
                the “non-deductible” from the deductible portion of gifts) is no
                longer allowed per IRS regulations. If you are not attending the
                event, however, you may make a general operating support grant
                in honor of the program.
                <br />
                <br />
              </Typography>

              <Typography>
                <b>Q: May I make my membership gift through my Account?</b>
                <br />
                <b>A:</b> No. Under the Pension Protection Act of 2006,
                memberships to museums, aquariums, zoos, etc. may not be paid
                through a grant from your Account because these memberships
                carry with them more than an incidental benefit.
                <br />
                <br />
              </Typography>

              <Typography>
                <b>Q: Can I encourage others to give to the fund?</b>
                <br />
                <b>A:</b> Yes. We encourage creating Campaigns from your Account
                to increase your impact around causes that are important to you
                however, organized fundraising activities (e.g. events, raffles,
                walks, etc.) on behalf of the Sponsor are not permitted.
                <br />
                <br />
              </Typography>
            </>
          }
        />

        <Divider
          style={{
            padding: "0px",
            marginTop: "0px",
            marginBottom: "30px",
          }}
        />
        <Typography
          style={{
            fontFamily: "Century Gothic",
            fontStyle: "normal",
            fontWeight: 400
          }}
          sx={{
            fontSize: { xs: "28px", lg:"44px", md: "46px" },
            lineHeight: { xs: "40px", lg: "50px", md: "50px" },
          }}
        >
          Glossary of Terms
        </Typography>
        <Paragraph
          children={
            <>
              <Typography>
                <br />
                <b>501(c)(3):</b>
                <br />
                Section of the Internal Revenue Code that designates an
                organization as charitable, tax-exempt and nonprofit.
                Organizations qualifying under the code include religious,
                educational, charitable, amateur athletic, scientific, or
                literary groups; organizations testing for public safety; or
                organizations involved in prevention of cruelty to children or
                animals. Most organizations seeking foundation or corporate
                contributions have a 501(c)(3) status. the program.
              </Typography>

              <Typography>
                <br />
                <b>Advised Account:</b>
                <br />A Donor Advised Fund through which the Account Holder (or
                person or committee designated by the Account Holder) can advise
                the community foundation on charitable distributions. The
                recommendations are only advisory; the governing body of the
                community foundation has legal control over all distributions.
              </Typography>

              <Typography>
                <br />
                <b>National Sponsoring Organization:</b>
                <br />A tax-exempt organization with national reach in
                fundraising and grantmaking. National Charities include
                independent organizations and other charitable organizations
                affiliated with financial institutions. Givinga Foundation is an
                Independent National Sponsoring Organization.
              </Typography>

              <Typography>
                <br />
                <b>Component Funds:</b>
                <br />
                Multiple funds (such as Donor Advised Funds) which comprise a
                community foundation. A Donor Advised Fund is a component fund
                and asset of Sponsor (a qualified public charity), and as such,
                any contribution to a component fund is automatically treated as
                a gift to a public charity.
              </Typography>

              <Typography>
                <br />
                <b>Designated Fund:</b>
                <br />A fund from which distributions are restricted to one or
                more specific public charities that have been named by the
                donor. Sponsor oversees the annual grant process, ensuring that
                the organization has not changed its legal status and that its
                purpose remains charitable in nature. In the event the
                organization ceases to operate or changes its purpose, Sponsor
                will see that the fund is used to continue the donor’s
                charitable objectives.
              </Typography>

              <Typography>
                <br />
                <b>Donor-Advised Fund:</b>
                <br />A philanthropic giving vehicle administered by a
                charitable Sponsoring Organization. A donor advised fund allows
                donors to establish and fund the account by making irrevocable,
                tax deductible contributions to the charitable Sponsoring
                Organization. Donors then recommend grants from those funds to
                other charitable organizations.
              </Typography>

              <Typography>
                <br />
                <b>Endowed Fund:</b>
                <br />
                Also known as a permanent fund, an endowed fund is intended to
                be kept in perpetuity and invested to generate income for
                charitable grants.
              </Typography>

              <Typography>
                <br />
                <b>Field of Interest Fund:</b>
                <br />A fund from which distributions are restricted to a
                charitable purpose specified by the donor (for example, the
                arts, education, or summer activities for the youth of Boston).
              </Typography>

              <Typography>
                <br />
                <b>Private Foundation:</b>
                <br />A foundation that receives most of its income from, and is
                subject to the control of, an individual or other single or
                limited source. Also, the technical IRS term for an organization
                which is tax- exempt under Section 501(c)(3) but does not have
                to meet a public support test, so therefore is classified as a
                private foundation under the Internal Revenue Code.
              </Typography>

              <Typography>
                <br />
                <b>Private Operating Foundation:</b>
                <br />A private foundation (see above) that uses its income to
                operate a charitable activity, such as a school or camp, rather
                than to make grants. A private operating foundation has a
                different tax status from either a public charity or a private
                non-operating foundation.
              </Typography>

              <Typography>
                <br />
                <b>Program-related Investments (PRIs):</b>
                <br />
                Sometimes referred to as impact investments, PRIs are
                investments made by foundations to support charitable activities
                that involve the potential return of capital within an
                established time frame. PRIs include financing methods commonly
                associated with banks of other private investors, such as loans
                and loan guarantees.
              </Typography>

              <Typography>
                <br />
                <b>Single-Issue Charities:</b>
                <br />A tax-exempt organization that works in a specific topic
                area. Some common Single-Issue Charities include universities,
                Jewish federations, other faith-based charities, and
                issue-specific charities, such as those in the environmental,
                social justice or international relief arenas.
              </Typography>
            </>
          }
        />
      </Box>
    </Container>
  );
};

export default Main;
