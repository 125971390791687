import React from "react";
import Box from "@mui/material/Box";

import Container from "components/Container";
import { Form } from "components/login-signup";
import SideScreen from "components/login-signup/SideScreen";
import SigninSignup from "assets/SigninSignup.png";
import Layout from "layout/index.js";


const Signup = () => {
  return (
    <Container
      paddingX={0}
      paddingY={0}
      sx={{
        maxWidth: "100vw",
        height: { md: "auto", sm: "100vh", xs: "100vh" },
      }}
    >
      <Layout>
        <Box
          display={"flex"}
          flexDirection={{ xs: "column", md: "row" }}
          position={"relative"}
        >
          <SideScreen />

          {/* form*/}
          <Box
            width={1}
            order={{ xs: 2, md: 2 }}
            display={"flex"}
            alignItems={"center"}
            sx={{
              maxWidth: { sm: "100%", md: "700px", lg: "49vw" },
              padding: { sm: "0", md: "0 0 0 5%", lg: "0 0 0 6%" },
              minHeight: { lg: "auto", md: "auto", sm: "100vh", xs: "100vh" },

              background: {
                lg: "white",
                md: "white",
                sm: `url(${SigninSignup}) no-repeat center`,
                xs: `url(${SigninSignup}) no-repeat center`,
              },
              backgroundSize: { sm: "cover", xs: "cover" },
            }}
          >
            <Container>
              <Form signup={true} />
            </Container>
          </Box>
        </Box>
        <hr style={{ padding: "0px", margin: "0px" }} />
      </Layout>
    </Container>
  );
};

export default Signup;
