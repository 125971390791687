/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Container from "components/Container";

const Hero = ({ path, title, lastUpdated, image }) => {
  const GridItemHeadlineBlock = () => (
    <Box data-aos="fade-up">
      <Typography
        component="p"
        color="text.primary"
        sx={{
          display: "flex",
          alignItems: "center",
          fontFamily: "Arial",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: { sm: "18px", md: "22px" },
          lineHeight: { md: "60px", sm: "20px" },
          color: "#FFFFFF",
          justifyContent: "center",
          letterSpacing: "0em",
        }}
      >
        {path}
      </Typography>
      <Typography
        sx={{
          fontFamily: "CenturyGothic",
          fontStyle: "normal",
          textAlign: "center",
          fontWeight: 400,
          fontSize: { xs: "30px", sm: "30px", md: "50px", lg: "50px" },
          lineHeight: { xs: "35px", sm: "35px", md: "50px", lg: "60px" },
          letterSpacing: "0px",
          mb: { sx: 0, md: "10px" },
          color: "#FFFFFF",
        }}
      >
        {title}
      </Typography>
      <Typography
        component="p"
        color="text.primary"
        sx={{
          display: "flex",
          alignItems: "center",
          fontFamily: "Arial",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: { sm: "18px", md: "22px" },
          lineHeight: { lg: "60px", md: "50px", sm: "20px" },
          color: "#FFFFFF",
          justifyContent: "center",
          letterSpacing: "0em",
        }}
      >
        {lastUpdated}
      </Typography>
    </Box>
  );

  return (
    <Box
      position={"relative"}
      sx={{
        background: `url(${image}) no-repeat center`,
        backgroundSize: "cover",
        backdropFilter: "brightness(0)",
        height: { lg: "450px", md: "450px", xs: "250px" },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 1,
          height: 1,
          opacity: "0.8",
          zIndex: 1,
        }}
      />
      <Container
        position={"relative"}
        zIndex={2}
        sx={{
          padding: "0px !important",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            sx={{
              width: { md: 1, sm: 1, lg: 1 },
              justifyContent: "center",
            }}
          >
            <GridItemHeadlineBlock />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
