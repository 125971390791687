import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { alpha, useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";

import logo from "assets/logo.svg";
import CustomButton from "components/CustomButton";
import { useNavigate } from "react-router-dom";

const Topbar = ({ onSidebarOpen }) => {
  const theme = useTheme();
  const [activeLink, setActiveLink] = React.useState("");
  const navigate = useNavigate();

  React.useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : "");
  }, []);

  const navigation = [
    { path: "/organization-signup", title: "Organization Sign Up" },
    { path: "/signup", title: "User Registration" },
    { path: "/about-us", title: "About Us" },
  ];

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      width={1}
    >
      <Box
        display={"flex"}
        component="a"
        href="/"
        title="logo"
        width={{ xs: 100, md: 120 }}
      >
        <Box
          component={"img"}
          src={logo}
          loading="lazy"
          decoding={"async"}
          sx={{
            maxHeight: "64px",
            width: { md: "253px", sm: "207px", xs: "207px" },
            height: { md: 1, sm: "48px", xs: "48px" },
          }}
          alt={"logo"}
        />
      </Box>
      <Box
        sx={{ display: { xs: "none", md: "flex", textAlign: "center" } }}
        alignItems={"center"}
      >
        {navigation.map((item, index) => {
          return (
            <Box sx={{ marginLeft: { sm: 1, md: 0.9, lg: 3 } }} key={index}>
              <Button
                // component={"a"}
                // href={item.path}
                fullWidth
                sx={{
                  justifyContent: "flex-start",
                  color:
                    activeLink === item.path
                      ? theme.palette.primary.main
                      : theme.palette.text.primary,
                  // fontWeight: activeLink === item.path ? 600 : 400,
                  fontSize: "16px",
                  lineHeight: "18px",
                  fontFamily: "Arial",
                  textTransform: "inherit",
                  height: "50px",
                  letterSpacing: "0em",
                }}
                onClick={() => navigate(item.path)}
              >
                {item.title}
              </Button>
            </Box>
          );
        })}

        <Box sx={{ marginLeft: { sm: 1, md: 0.8, lg: 4 } }}>
          <CustomButton
            component={"a"}
            title={"Login"}
            color={"white"}
            width="130px"
            height="50px"
            fontSize={"18px"}
            lineHeight={"21px"}
            fontFamily={"Arial"}
            background={theme.palette.primary.main}
            href="https://admin.directaffect.net"
          />
        </Box>
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }} alignItems={"center"}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={"outlined"}
          sx={{
            borderRadius: 2,
            minWidth: "auto",
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
            border: "none",
            "&:hover": {
              border: "none",
            },
          }}
        >
          <MenuIcon sx={{ fontSize: "40px" }} />
        </Button>
      </Box>
    </Box>
  );
};

export default Topbar;
