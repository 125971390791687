import React from "react";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import Container from "components/Container";
import Heading from "components/Typographies/Heading";
import Paragraph, { style } from "components/Typographies/Paragraph";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Main = () => {
  const navigate = useNavigate();
  
  const termsNavigate = () => {
    navigate("/policies");
  };

  const privacyNavigate = () => {
    navigate("/privacy-policy");
  };

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: {
          lg: "98px 32px 108px 32px !important",
          md: "98px 32px 108px 32px !important",
          sm: "50px 32px 50px 32px !important",
          xs: "40px 32px 40px 32px !important",
        },
        maxWidth: "1364px !important",
      }}
    >
      <Box>
        <Paragraph
          children={
            <>
              These Terms of Service (the “Terms”) are a legally binding
              contract between you and Direct Affect, Inc. a California Benefit Corporation (“DA”, or “Direct Affect”).
              <br />
              <br />
              Please note that this agreement contains an arbitration clause. By
              agreeing to the Terms, you agree to resolve all disputes through
              binding individual arbitration, which means that you waive any
              right to have those disputes decided by a judge or jury.
              <br />
              <br />
              This contract sets out your rights and responsibilities when you
              use the Direct Affect mobile app, and the other services provided
              by DA (our “Services”), so please read it carefully. By using any
              of our Services, you are agreeing to the Terms.
              <br />
              <br />
              DA links Non-Profit Organizations (“NPO’s”) with their supporters.
              If you use any DA Services, you agree to these Terms (as changed
              from time to time) and you accept and consent to the practices
              described in our               <Typography
                component="span"
                color="#1AB29F"
                onClick={() => privacyNavigate()}
                sx={{
                  textDecoration: "underLine",
                  cursor: "pointer",
                  fontSize: "inherit",
                  lineHeight: "inherit",
                }}
              >
                Privacy Policy
              </Typography>. Our Privacy Policy details how
              your information is used when you use our Services. You further agree to the Policies and Procedures associated with your Personal Giving Account or Donor Advised Fund (DAF) so please read them{" "}
              <Typography
                component="span"
                color="#1AB29F"
                onClick={() => termsNavigate()}
                sx={{
                  textDecoration: "underLine",
                  cursor: "pointer",
                  fontSize: "inherit",
                  lineHeight: "inherit",
                }}
              >
                here
              </Typography>
              .
              <br />
            </>
          }
        />
        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
          }}
        />
        <Heading children={"1. Your Account with DA"} head />
        <Paragraph
          children={
            <>
              You’ll need to create an account with DA to use any of our
              Services. To use any of our Services:
              <br />
            </>
          }
        />
        <Paragraph
          children={
            <ul style={{ marginTop: "0px" }}>
              <li>
                You must be 18 years or older and you must provide accurate
                information about yourself. It’s prohibited to use false
                information or impersonate another person or company through
                your account
              </li>
              <li>
                You must be a U.S. citizen, residing in the U.S. and you must access our Services only from the U.S.
              </li>
              <li>
                You are responsible for your account and you should protect your
                account by ensuring that access to and use of your mobile device
                is properly protected.
              </li>
              <li>
                These Terms do not create any agency, partnership, joint
                venture, employment, or franchisee relationship between you and
                DA.
              </li>
            </ul>
          }
        />
        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
          }}
        />
        <Heading children={"2. Your Use of Our Services"} head />
        <Paragraph
          children={
            <>
              License to Use Our Services. We grant you a limited,
              non-exclusive, non-transferable, and revocable license to use our
              Services - subject to the Terms and the following restrictions in
              particular:
              <br />
            </>
          }
        />
        <Paragraph
          children={
            <ul style={{ marginTop: "0px" }}>
              <li>
                You agree that you will not violate any laws in connection with
                your use of the Platform. This includes any local, state and
                federal laws that may apply to you.
              </li>
              <li>
                You agree not to reverse engineer or attempt to obtain the
                source code of the Platform.
              </li>
              <li>
                You agree not to interfere with or try to disrupt our Services,
                for example by introducing a virus or other harmful computer
                code.
              </li>
              <li>
              You may not transfer, redistribute or sublicense the Licensed Services and, if you sell your Apple Device to a third party, you must remove our App from the Apple Device before doing so.  
              </li>
            </ul>
          }
        />{" "}
        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
          }}
        />
        <Heading children={"3. Termination"} head />
        <Paragraph
          children={
            <ul style={{ marginTop: "0px" }}>
              <li>You may terminate your account with DA at any time.</li>
              <li>
                We may terminate or suspend your account if we have reason to
                believe you have violated our Terms.{" "}
              </li>
              <li>
                If you or DA terminate your account, you may lose any
                information associated with your account, including your giving
                history and reports.
              </li>
              <li>
                We may discontinue the Service and we reserves the right to
                change, suspend, or discontinue the Services for you, any or all
                users, at any time, for any reason.
              </li>
              <li>
                Survival. The Terms will remain in effect even after your access
                to the Service is terminated, or your use of the Service ends.
              </li>
            </ul>
          }
        />{" "}
        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
          }}
        />
        <Heading children={"4. Warranties and Limitation of Liability "} head />
        <Paragraph
          children={
            <>
              You may come across materials that you find offensive or
              inappropriate while using our Services. We make no representations
              concerning any content posted by NPO’s on our Platform and we are
              not responsible for the accuracy, copyright compliance, legality,
              or decency of content posted by NPOs that you are associated with
              through our Platform. You release us from all liability relating
              to that content.
              <br />
              <br />
            </>
          }
        />
        <Paragraph
          children={
            <>
              <>
                WARRANTIES. YOU UNDERSTAND THAT DA SERVICES ARE PROVIDED “AS IS”
                AND WITHOUT ANY KIND OF WARRANTY (EXPRESS OR IMPLIED). WE ARE
                EXPRESSLY DISCLAIMING ANY WARRANTIES OF TITLE, NON-INFRINGEMENT,
                MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE, AS WELL
                AS ANY WARRANTIES IMPLIED BY A COURSE OF PERFORMANCE, COURSE OF
                DEALING, OR USAGE OF TRADE.
              </>
              <br />
              <br />
              <>
                WE DO NOT GUARANTEE THAT: (I) THE SERVICES WILL BE SECURE OR
                AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (II) ANY DEFECTS
                OR ERRORS WILL BE CORRECTED; (III) THE SERVICES WILL BE FREE OF
                VIRUSES OR OTHER HARMFUL MATERIALS; OR (IV) THE RESULTS OF USING
                THE SERVICES WILL MEET YOUR EXPECTATIONS. YOU USE THE SERVICES
                SOLELY AT YOUR OWN RISK.
              </>
              <br />
              <br />
              <>
                LIABILITY LIMITS. TO THE FULLEST EXTENT PERMITTED BY LAW,
                NEITHER DA, NOR OUR EMPLOYEES OR DIRECTORS SHALL BE LIABLE TO
                YOU FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, OR
                PUNITIVE DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE
                SERVICES OR THESE TERMS.
              </>
            </>
          }
        />
        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
          }}
        />
        <Heading children={"5. Indemnification "} head />
        <Paragraph
          children={
            <>
              You agree to defend and indemnify DA and our employee’s and hold
              us harmless from any legal claim or demand (including reasonable
              attorney’s fees) that arises from your actions, your use (or
              misuse) of our Services, your breach of the Terms, or you or your
              infringement of someone else’s rights.
            </>
          }
        />{" "}
        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
          }}
        />
        <Heading children={"6. Governing Law"} head />
        <Paragraph
          letterSpacing="-0.2px"
          children={
            <>
              The Terms are governed by the laws of the State of California,
              without regard to its conflict of laws rules, and the laws of the
              United States of America. <br />
              <br />
            </>
          }
        />
        <Paragraph
          children={
            <ul style={{ marginTop: "0px" }}>
              <li>
                Arbitration. You and DA agree that any dispute or claim arising
                from or relating to the Terms shall be finally settled by final
                and binding arbitration, administered by the American
                Arbitration Association (the “AAA”) under its Consumer
                Arbitration Rules (the "AAA Rules") then in effect unless
                otherwise required by law.
              </li>
              <br />
              <li>
                Any arbitration or mediation under the Terms will take place on
                an individual basis. You understand that by agreeing to the
                Terms, you and DA are each waiving the right to trial by jury or
                to participate in a class action lawsuit. Class arbitrations
                shall only be available if requested by either party under its
                Class Action Arbitration Rules and approved by the arbitration
                entity. Notwithstanding the foregoing, each party shall have the
                right to bring an action in a court of proper jurisdiction for
                injunctive or other equitable or conservatory relief, pending a
                final decision by the arbitrator or mediator.
              </li>
              <br />
              <li>
                Costs of Arbitration. Payment for any and all reasonable AAA
                filing, administrative, and arbitrator fees will be in
                accordance with the Consumer Arbitration Rules. If the value of
                your claim does not exceed $10,000 USD, DA will pay for the
                reasonable filing, administrative, and arbitrator fees
                associated with the arbitration, unless the arbitrator finds
                that either the substance of your claim or the relief sought was
                frivolous or brought for an improper purpose.
              </li>
              <br />
              <li>
                Forum. Any legal action against DA related to our Services must
                be filed and take place in Santa Barbara, California. For all
                actions under the AAA Rules, the proceedings may be filed where
                your residence is, or in California, and any in-person hearings
                will be conducted at a location which is reasonably convenient
                to both parties taking in to account their ability to travel and
                other pertinent circumstances. For any actions not subject to
                arbitration or mediation, you and DA agree to submit to the jurisdiction of a state or federal court located in
                California.
              </li>
              <br />
            </ul>
          }
        />
        <Divider
          style={{
            padding: "0px",
          }}
        />
        <Heading children={"7. Changes to the Terms"} head />
        <Paragraph
          children={
            <>
              We may update these Terms from time to time by posting the changes
              through the Services and/or sending you an email or message about
              the changes. You are responsible for reviewing and becoming
              familiar with any changes. Your use of the Services following the
              changes constitutes your acceptance of the updated Terms.
            </>
          }
        />{" "}
        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
          }}
        />
        <Heading children={"8. Legal"} head />
        <Paragraph
          children={
            <>
              The Terms, including all of the policies that make up the Terms,
              supersede any other agreement between you and DA regarding the
              Services. If any part of the Terms is found to be unenforceable,
              that part will be limited to the minimum extent necessary so that
              the Terms will otherwise remain in full force and effect. Our
              failure to enforce any part of the Terms is not a waiver of our
              right to later enforce that or any other part of the Terms. We may
              assign any of our rights and obligations under the Terms.
            </>
          }
        />{" "}
        <Divider
          style={{
            padding: "0px",
            marginTop: "30px",
          }}
        />
        <Heading children={"9. Contact Information"} head />
        <Paragraph
          children={
            <> 
              If you have any questions about the Terms, please email us at
              <Typography
                component="a"
                href="mailto:support@directaffect.net"
                sx={{
                  ...style,
                  color: "#1cb29f",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                ml={1}
              >
                support@directaffect.net
              </Typography>
            </>
          }
        />
      </Box>
    </Container>
  );
};

export default Main;
