import { Banner } from 'components/TwoColumnBanner'
import sectionSideImage from 'assets/support-section.jpg'
import { Box, Container } from '@mui/material';

const Support = () => {
  const description = 'inform, engage & motivate a new generation of supporters'
  return (
    
    <Container
      sx={{
        maxWidth: "1680px !important",
        padding: "0px !important",
      }}
    >
      <Box container spacing={4} sx={{ mt: { lg: 5, md: 5, sm: 0, xs: 0 } }}>
        <Banner
          image={sectionSideImage}
          tag={"The way to"}
          title={description}
          hideSignUpButton
        />
      </Box>
    </Container>
  )
};

export default Support;
