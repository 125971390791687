import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

import React from "react";

const CustomButton = ({
  title,
  margin,
  color,
  height,
  width,
  background,
  fontSize,
  borderRadius,
  fontFamily,
  fontWeight,
  justifyContent,
  padding,
  border,
  onClick,
  maxWidth,
  variant,
  textAlign,
  zIndex,
  letterSpacing,
  lineHeight,
  inputType,
  as,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Button
      variant={variant || "contained"}
      target="blank"
      size="large"
      {...rest}
      sx={{
        lineHeight:lineHeight||"inherit",
        textTransform: "inherit",
        letterSpacing: letterSpacing || "inherit",
        background: background || theme.palette.primary.main,
        padding: padding || "0px",
        margin: margin || 0,
        color: color || theme.palette.text.primary,
        height: height || "auto",
        width: width || "auto",
        "&:hover": {
          background: background || theme.palette.primary.main,
        },
        fontSize: fontSize || "inherit",
        border: border || "none",
        boxSizing: "border-box",
        borderRadius: borderRadius || "5px",
        boxShadow: "none",
        fontFamily: fontFamily || "Arial",
        fontWeight: fontWeight || "inherit",
        justifyContent: justifyContent || "center",
        maxWidth: maxWidth || "auto",
        textAlign: textAlign || "center",
        zIndex: zIndex || 0,
      }}
      onClick={onClick}
      type={inputType}
    >
      {title}
    </Button>
  );
};

export default CustomButton;
