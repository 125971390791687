import React from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

const Input = ({
  title,
  titleStyle,
  inputStyle,
  placeHolder,
  formik,
  autoFocus,
  name
}) => {

  return (
    <>
      <Typography variant={"subtitle2"} sx={titleStyle} marginBottom={1}>
        {title}
      </Typography>
      <TextField
        variant="outlined"
        name={name || title}
        fullWidth
        value={formik.values.formikValue}
        onChange={formik.handleChange}
        error={formik.touched.formikValue && Boolean(formik.errors.formikValue)}
        helperText={formik.touched.formikValue && formik.errors.formikValue}
        placeholder={placeHolder}
        sx={{ ...inputStyle, background: "white" }}
        autoFocus={autoFocus}
        InputProps={{ sx: { px: "11px", fontSize: { lg: "20px", md: "18px", sm: "16px", xs: "14px" } } }}
        InputLabelProps={{ sx: { px: "11px" } }}
      />
      {formik.errors[name] && <div className="error-label">{formik.errors[name]}</div>}
    </>
  );
};

export default Input;
