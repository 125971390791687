import React from "react";
import Box from "@mui/material/Box";
import EmpoweringBG from "assets/empowerGroup.svg";
import EmpoweringBGMob from "assets/empoweringBGMob.png";
import EmpoweringLogo from "assets/empoweringLogo.png";
import Typography from "@mui/material/Typography";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Container from "components/Container";

const Empowering = () => {
  const theme = useTheme();

  const isSm = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });
  return (
    <Box
      component={"img"}
      height={1}
      width={1}
      loading="lazy"
      decoding={"async"}
      sx={{
        objectFit: { xs: "cover", md: "cover" },

        marginTop: { lg: 13, md: 13, sm: 8, xs: 8 },
        background: `url(${
          isSm ? EmpoweringBGMob : EmpoweringBG
        }) no-repeat center`,
        backgroundSize: "cover",
        backdropFilter: "brightness(0)",
        display: "flex",
        height: { xs: "840px", sm: "479px", md: "550px", lg: "679px",xl:"800px" },
      }}
    />
  );
};

export default Empowering;
